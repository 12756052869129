import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { getRangesForManufacturersService } from '@/lib/services/searchService'
import { getRangesForManufacturers, storeVehicleRanges } from '@/store/reducers/search'

export const getRangesForManufacturersEffect = action$ =>
  action$.pipe(
    ofType(getRangesForManufacturers),
    map(action => action.payload),
    switchMap(params =>
      from(getRangesForManufacturersService(params)).pipe(
        map(response => storeVehicleRanges(response)),
      ),
    ),
  )

export const rangesEffect = combineEpics(getRangesForManufacturersEffect)
