import { combineEpics, ofType } from 'redux-observable'
import { concat, EMPTY, from, iif, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { removeError } from '../reducers/error'
import { clearLeaseProfileState } from '../reducers/leaseProfile'
import { addLoader, removeLoader } from '../reducers/loader'
import {
  hydrateSearchParameters,
  INITIAL_STATE,
  landedOnCarSearchPage,
  landedOnCarSearchPageWithPath,
  landedOnCarSearchPageWithReference,
  landedOnVanSearchPage,
  landedOnVanSearchPageWithPath,
  landedOnVanSearchPageWithReference,
  storeAvailableFilters,
  storeIsSearchSaved,
  storeLeaseType,
  storePageNumber,
  storePagination,
  storeSavedSearchReference,
  storeSearchResults,
  storeSearchTotals,
  storeVehicleRanges,
  storeVehicleTypes,
} from '../reducers/search'
import { storeQueryString as storeQueryStringInSystem } from '../reducers/system'
import { storeQueryString } from '../reducers/user'
import { selectSearchParameters } from '../selectors/search'
import {
  FILTER_LEASE_TYPES_BUSINESS,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
} from '@/lib/constants'
import { ERRORS } from '@/lib/errors'
import { LOADERS } from '@/lib/loaders'
import {
  getSearchWithPathService,
  getSearchWithReferenceService,
} from '@/lib/services/searchService'
import { selectLoaderExists } from '@/store/selectors/loader'

export const storeQueryStringEffect = action$ =>
  action$.pipe(
    ofType(storeQueryString),
    map(action => action.payload),
    map(queryString => storeQueryStringInSystem(queryString)),
  )

export const searchPageLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults
  const ERROR = ERRORS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnCarSearchPage),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]
      const isFirstRender = !!queryString
      const existingSearchParams = selectSearchParameters(state$.value)
      const isSearchForVans = existingSearchParams.vehicleTypes[0] === FILTER_VEHICLE_TYPES_VANS
      const isAlreadyLoading = selectLoaderExists(state$.value, LOADERS.fetchingSearchResults)

      let searchParameters =
        isFirstRender || isSearchForVans ? INITIAL_STATE.searchParameters : existingSearchParams

      const params = {
        ...searchParameters,
        vehicleTypes: [FILTER_VEHICLE_TYPES_CARS],
        page: 1,
      }

      if (isAlreadyLoading) {
        return EMPTY
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        of(storeIsSearchSaved(false)),
        of(hydrateSearchParameters(searchParameters)),
        of(storePageNumber(params.page)),
      )
    }),
  )
}

export const searchPageWithReferenceLoadEffect = action$ => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnCarSearchPageWithReference),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]

      const params = {
        reference: url.split('/car-leasing/search/')[1].split('?')[0],
        page:
          !!queryString && queryString.includes('page=')
            ? Number(queryString.split('page=')[1].split('&')[0])
            : 1,
      }

      return concat(
        of(addLoader(LOADER)),
        iif(
          () => queryString === undefined || !queryString.includes('page='),
          from(getSearchWithReferenceService(params)).pipe(
            switchMap(response =>
              concat(
                of(clearLeaseProfileState()),
                of(storeSearchResults(response.results)),
                of(storeAvailableFilters(response.availableFilters)),
                of(
                  storeSearchTotals({
                    vehicleTotal: response.vehicleTotal,
                    pricesTotal: response.pricesTotal,
                    resultsTotal: response.resultsTotal,
                  }),
                ),
                of(storePagination(response.pagination)),
                of(hydrateSearchParameters(response.searchRequest)),
                of(storeLeaseType(response.searchRequest.leaseTypes[0])),
                of(storeVehicleRanges(response.availableFilters.ranges)),
                of(storeVehicleTypes(FILTER_VEHICLE_TYPES_CARS)),
                of(storeIsSearchSaved(true)),
                of(removeLoader(LOADER)),
              ),
            ),
            catchError(() => of(storePageNumber(1)), of(removeLoader(LOADER))),
          ),
          of(storePageNumber(params.page)),
        ),
      )
    }),
  )
}

export const searchPageWithPathLoadEffect = action$ => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnCarSearchPageWithPath),
    map(action => action.payload),
    switchMap(url => {
      const key = url.split('?')[0].substring(1)
      const queryString = url.split('?')[1]
      const params = {
        urlKey: key,
      }

      return concat(
        of(addLoader(LOADER)),
        iif(
          () => queryString === undefined || !queryString.includes('page='),
          from(getSearchWithPathService(params)).pipe(
            switchMap(response =>
              concat(
                of(clearLeaseProfileState()),
                of(storeSearchResults(response.results)),
                of(storeAvailableFilters(response.availableFilters)),
                of(
                  storeSearchTotals({
                    vehicleTotal: response.vehicleTotal,
                    pricesTotal: response.pricesTotal,
                    resultsTotal: response.resultsTotal,
                  }),
                ),
                of(storePagination(response.pagination)),
                of(storeLeaseType(response.searchRequest.leaseTypes[0])),
                of(storeSavedSearchReference('')),
                of(hydrateSearchParameters(response.searchRequest)),
                of(storeVehicleRanges(response.availableFilters.ranges)),
                of(storeVehicleTypes(FILTER_VEHICLE_TYPES_CARS)),
                of(removeLoader(LOADER)),
              ),
            ),
            catchError(err => {
              if (err.status === 404) {
                return concat(of(removeLoader(LOADER)))
              }

              return concat(of(storePageNumber(1)), of(removeLoader(LOADER)))
            }),
          ),
          of(
            storePageNumber(
              !!queryString && queryString.includes('page=')
                ? Number(queryString.split('page=')[1].split('&')[0])
                : 1,
            ),
          ),
        ),
      )
    }),
  )
}

export const vanSearchPageLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults
  const ERROR = ERRORS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnVanSearchPage),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]
      const isFirstRender = !!queryString && !queryString.includes('page=')
      const existingSearchParams = selectSearchParameters(state$.value)
      const isSearchForCars = existingSearchParams.vehicleTypes[0] === FILTER_VEHICLE_TYPES_CARS

      const searchParameters =
        isFirstRender || isSearchForCars ? INITIAL_STATE.searchParameters : existingSearchParams

      const params = {
        ...searchParameters,
        leaseTypes: [FILTER_LEASE_TYPES_BUSINESS],
        vehicleTypes: [FILTER_VEHICLE_TYPES_VANS],
        page: 1,
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        of(hydrateSearchParameters(searchParameters)),
        of(storeVehicleTypes(FILTER_VEHICLE_TYPES_VANS)),
        of(storeLeaseType(FILTER_LEASE_TYPES_BUSINESS)),
        of(storeIsSearchSaved(false)),
        of(storePageNumber(params.page)),
      )
    }),
  )
}

export const vanSearchPageWithReferenceLoadEffect = action$ => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnVanSearchPageWithReference),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]

      const params = {
        reference: url.split('/van-leasing/search/')[1].split('?')[0],
        page:
          !!queryString && queryString.includes('page=')
            ? Number(queryString.split('page=')[1].split('&')[0])
            : 1,
      }

      return concat(
        of(addLoader(LOADER)),
        iif(
          () => queryString === undefined || !queryString.includes('page='),
          from(getSearchWithReferenceService(params)).pipe(
            switchMap(response =>
              concat(
                of(clearLeaseProfileState()),
                of(storeSearchResults(response.results)),
                of(storeAvailableFilters(response.availableFilters)),
                of(
                  storeSearchTotals({
                    vehicleTotal: response.vehicleTotal,
                    pricesTotal: response.pricesTotal,
                    resultsTotal: response.resultsTotal,
                  }),
                ),
                of(storePagination(response.pagination)),
                of(hydrateSearchParameters(response.searchRequest)),
                of(storeLeaseType(FILTER_LEASE_TYPES_BUSINESS)),
                of(storeVehicleTypes(FILTER_VEHICLE_TYPES_VANS)),
                of(storeVehicleRanges(response.availableFilters.ranges)),
                of(storeIsSearchSaved(true)),
                of(removeLoader(LOADER)),
              ),
            ),
            catchError(() => of(storePageNumber(1)), of(removeLoader(LOADER))),
          ),
          of(storePageNumber(params.page)),
        ),
      )
    }),
  )
}

export const vanSearchPageWithPathLoadEffect = action$ => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnVanSearchPageWithPath),
    map(action => action.payload),
    switchMap(url => {
      const key = url.split('?')[0].substring(1)
      const queryString = url.split('?')[1]
      const params = {
        urlKey: key,
      }

      return concat(
        of(addLoader(LOADER)),
        iif(
          () => queryString === undefined || !queryString.includes('page='),
          from(getSearchWithPathService(params)).pipe(
            switchMap(response =>
              concat(
                of(clearLeaseProfileState()),
                of(storeSearchResults(response.results)),
                of(storeAvailableFilters(response.availableFilters)),
                of(
                  storeSearchTotals({
                    vehicleTotal: response.vehicleTotal,
                    pricesTotal: response.pricesTotal,
                    resultsTotal: response.resultsTotal,
                  }),
                ),
                of(storePagination(response.pagination)),
                of(storeLeaseType(FILTER_LEASE_TYPES_BUSINESS)),
                of(storeSavedSearchReference('')),
                of(hydrateSearchParameters(response.searchRequest)),
                of(storeVehicleRanges(response.availableFilters.ranges)),
                of(storeVehicleTypes(FILTER_VEHICLE_TYPES_VANS)),
                of(removeLoader(LOADER)),
              ),
            ),
            catchError(err => {
              if (err.status === 404) {
                return concat(of(removeLoader(LOADER)))
              }

              return concat(of(storePageNumber(1)), of(removeLoader(LOADER)))
            }),
          ),
          of(
            storePageNumber(
              !!queryString && queryString.includes('page=')
                ? Number(queryString.split('page=')[1].split('&')[0])
                : 1,
            ),
          ),
        ),
      )
    }),
  )
}

export const searchPageEffect = combineEpics(
  storeQueryStringEffect,
  searchPageLoadEffect,
  searchPageWithReferenceLoadEffect,
  searchPageWithPathLoadEffect,
  vanSearchPageLoadEffect,
  vanSearchPageWithReferenceLoadEffect,
  vanSearchPageWithPathLoadEffect,
)
