import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  list: [],
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState: INITIAL_STATE,
  reducers: {
    addLoader: (state, action) => {
      if (action.payload) {
        state.list = [...state.list.filter(i => i !== action.payload), action.payload]
      }
    },
    removeLoader: (state, action) => {
      if (action.payload) {
        state.list = [...state.list.filter(item => item !== action.payload)]
      }
    },
    clearLoaders: () => {
      return INITIAL_STATE
    },
  },
})

export const { addLoader, removeLoader, clearLoaders } = loaderSlice.actions

export default loaderSlice.reducer
