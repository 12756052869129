import { combineEpics, ofType } from 'redux-observable'
import { concat, from, iif, of } from 'rxjs'
import { catchError, debounceTime, delay, filter, map, switchMap } from 'rxjs/operators'

import { storeAiInteractionDetails, storeAllSearchParameters } from '../reducers/search'
import {
  constants,
  FILTER_INITIAL_PAYMENT_MAX,
  FILTER_INITIAL_PAYMENT_MIN,
  FILTER_LEASE_TYPES,
  FILTER_LEASE_TYPES_BUSINESS,
  FILTER_MANUFACTURERS,
  FILTER_MONTHLY_PAYMENT_MAX,
  FILTER_MONTHLY_PAYMENT_MIN,
} from '@/lib/constants'
import { ERRORS } from '@/lib/errors'
import { LOADERS } from '@/lib/loaders'
import {
  getRangesForManufacturersService,
  getSearchWithAiService,
  saveSearchService,
  submitSearchService,
} from '@/lib/services/searchService'
import { checkForUser } from '@/lib/utilities/account'
import { addVatToPrice, removeVatFromPrice } from '@/lib/utilities/search'
import { postAnalyticsEvent, SEND_SEARCH_ANALYTICS } from '@/store/actions/event'
import { addError, removeError } from '@/store/reducers/error'
import { clearLeaseProfileState } from '@/store/reducers/leaseProfile'
import { addLoader, removeLoader } from '@/store/reducers/loader'
import {
  clearSelectedRangesForSelectedManufacturer,
  getSearchResultsFromAi,
  INITIAL_STATE,
  removeVehicleRanges,
  removeVehicleRangesFromSearchParameters,
  saveSearch,
  storeAvailableFilters,
  storeIsSearchSaved,
  storePageNumber,
  storePagination,
  storeSavedSearchReference,
  storeSearchMultiParameters,
  storeSearchParameters,
  storeSearchResults,
  storeSearchTotals,
  storeVehicleRanges,
  toggleBudgetPrices,
} from '@/store/reducers/search'
import {
  selectCountOfSearchParameters,
  selectInitialPaymentMaxSearchParams,
  selectInitialPaymentMinSearchParams,
  selectMonthlyMaxSearchParams,
  selectMonthlyMinSearchParams,
  selectSearchAnalyticsEventData,
  selectSearchParameters,
  selectSearchResultDeals,
  selectVehicleRanges,
} from '@/store/selectors/search'

const addLoaderObservable = (state$, DELAY, LOADER) =>
  selectSearchResultDeals(state$.value).length === 0
    ? of(addLoader(LOADER))
    : of(addLoader(LOADER)).pipe(delay(DELAY))

const searchRequest = (state$, ERROR, LOADER) =>
  from(submitSearchService(selectSearchParameters(state$.value))).pipe(
    switchMap(response =>
      concat(
        of(addLoader(LOADERS.fetchingSearchResults)),
        of(clearLeaseProfileState()),
        of(storeSearchResults(response.results)),
        of(storeAvailableFilters(response.availableFilters)),
        of(
          storeSearchTotals({
            vehicleTotal: response.vehicleTotal,
            pricesTotal: response.pricesTotal,
            resultsTotal: response.resultsTotal,
          }),
        ),
        of(storePagination(response.pagination)),
        of(removeLoader(LOADER)),
      ),
    ),
    catchError(() =>
      concat(
        of(removeLoader(LOADER)),
        of(
          addError({
            key: ERROR,
            message: constants.errorMessages.fetchingDeals,
          }),
        ),
      ),
    ),
  )

export const getSearchResultsFromPageChangeEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults
  const ERROR = ERRORS.fetchingSearchResults

  return action$.pipe(
    ofType(storePageNumber),
    switchMap(() =>
      concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        of(storeIsSearchSaved(false)),
        of(storeSavedSearchReference('')),
        searchRequest(state$, ERROR, LOADER),
      ),
    ),
  )
}

export const getRangesFromSearchParametersEffect = (action$, state$) => {
  return action$.pipe(
    ofType(storeSearchMultiParameters),
    map(action => action.payload),
    filter(payload => payload.filter === FILTER_MANUFACTURERS),
    switchMap(payload =>
      concat(
        of(removeVehicleRangesFromSearchParameters(payload.value)),
        of(removeVehicleRanges(payload.value)),
        from(getRangesForManufacturersService(state$.value.search.searchParameters)).pipe(
          switchMap(response => concat(of(storeVehicleRanges(response)))),
          debounceTime(constants.searchDebounceDelay),
        ),
      ),
    ),
  )
}

export const setLeaseTypeFromSearchParametersEffect = (action$, state$) =>
  action$.pipe(
    ofType(storeSearchParameters),
    map(action => action.payload),
    filter(payload => payload.filter === FILTER_LEASE_TYPES),
    switchMap(params => {
      const monthlyMin = selectMonthlyMinSearchParams(state$.value)
      const monthlyMax = selectMonthlyMaxSearchParams(state$.value)
      const initialMin = selectInitialPaymentMinSearchParams(state$.value)
      const initialMax = selectInitialPaymentMaxSearchParams(state$.value)
      const isBusiness = params.value.includes(FILTER_LEASE_TYPES_BUSINESS)

      return concat(
        iif(
          () => !!monthlyMin,
          of(
            toggleBudgetPrices({
              value: isBusiness ? addVatToPrice(monthlyMin) : removeVatFromPrice(monthlyMin),
              filter: FILTER_MONTHLY_PAYMENT_MIN,
            }),
          ),
        ),
        iif(
          () => !!monthlyMax && monthlyMax !== 9999999,
          of(
            toggleBudgetPrices({
              value: isBusiness ? addVatToPrice(monthlyMax) : removeVatFromPrice(monthlyMax),
              filter: FILTER_MONTHLY_PAYMENT_MAX,
            }),
          ),
        ),
        iif(
          () => !!initialMin,
          of(
            toggleBudgetPrices({
              value: isBusiness ? addVatToPrice(initialMin) : removeVatFromPrice(initialMin),
              filter: FILTER_INITIAL_PAYMENT_MIN,
            }),
          ),
        ),
        iif(
          () => !!initialMax && initialMax !== 9999999,
          of(
            toggleBudgetPrices({
              value: isBusiness ? addVatToPrice(initialMax) : removeVatFromPrice(initialMax),
              filter: FILTER_INITIAL_PAYMENT_MAX,
            }),
          ),
        ),
        of(storePageNumber(1)),
      )
    }),
  )

export const sendSearchAnalyticsFromVehicleTagChangeEffect = (action$, state$) =>
  action$.pipe(
    ofType(storeSearchMultiParameters),
    map(action => action.payload),
    switchMap(() => {
      const event$ = of(
        postAnalyticsEvent({
          event: constants.eventTypes.searchRequest,
          payload: selectSearchAnalyticsEventData(state$.value),
        }),
      )

      return iif(
        () =>
          selectCountOfSearchParameters(state$.value) > 0 &&
          JSON.stringify(INITIAL_STATE.searchParameters) !==
            JSON.stringify(selectSearchParameters(state$.value)),
        event$,
      )
    }),
    debounceTime(constants.analyticsEventDebounceDelay),
  )

export const sendSearchAnalyticsEffect = (action$, state$) =>
  action$.pipe(
    ofType(SEND_SEARCH_ANALYTICS),
    map(action => action.payload),
    switchMap(url => {
      const event$ = of(
        postAnalyticsEvent({
          event: constants.eventTypes.searchRequest,
          path: url.path,
          payload: selectSearchAnalyticsEventData(state$.value),
        }),
      )

      return iif(
        () =>
          selectCountOfSearchParameters(state$.value) > 0 &&
          JSON.stringify(INITIAL_STATE.searchParameters) !==
            JSON.stringify(selectSearchParameters(state$.value)),
        event$,
      )
    }),
    debounceTime(constants.analyticsEventDebounceDelay),
  )

export const removeVehicleRangesFromSearchParametersEffect = (action$, state$) =>
  action$.pipe(
    ofType(removeVehicleRangesFromSearchParameters),
    map(action => action.payload),
    switchMap(manufacturer => {
      const ranges = selectVehicleRanges(state$.value)
      const manufacturerRanges = ranges
        .filter(range => range.manufacturer === manufacturer)
        .map(range => range.id)

      return of(clearSelectedRangesForSelectedManufacturer(manufacturerRanges))
    }),
  )

export const saveSearchEffect = (action$, state$) => {
  const LOADER = LOADERS.savingSearch
  const API_ERROR = ERRORS.savingSearch
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(saveSearch),
    map(action => action.payload),
    switchMap(payload =>
      concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token =>
            from(
              saveSearchService({
                ...selectSearchParameters(state$.value),
                name: payload && payload !== '' ? payload : undefined,
                token,
              }),
            ).pipe(
              switchMap(response =>
                concat(
                  of(storeSavedSearchReference(response.searchReference)),
                  of(removeLoader(LOADER)),
                ),
              ),
              catchError(() =>
                concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.savingSearch,
                    }),
                  ),
                ),
              ),
            ),
          ),
          catchError(() =>
            concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  )
}

export const getSearchResultsFromAiEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingResultsFromAiSearch
  const API_ERROR = ERRORS.fetchingResultsFromAiSearch
  const USER_ERROR = ERRORS.checkForUser
  const DELAY = constants.loaderDelay

  return action$.pipe(
    ofType(getSearchResultsFromAi),
    map(action => action.payload),
    switchMap(({ query, conversationId }) => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        of(storeIsSearchSaved(false)),
        of(storeSavedSearchReference('')),
        addLoaderObservable(state$, DELAY, LOADER),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getSearchWithAiService({ query, token, conversationId })).pipe(
              switchMap(response =>
                concat(
                  of(storeSearchResults(response.results)),
                  of(storeAiInteractionDetails(response.aiInteractionDetails)),
                  of(storeAvailableFilters(response.availableFilters)),
                  of(
                    storeSearchTotals({
                      vehicleTotal: response.vehicleTotal,
                      pricesTotal: response.pricesTotal,
                      resultsTotal: response.resultsTotal,
                    }),
                  ),
                  of(storeAllSearchParameters(response.searchRequest)),
                  of(storePagination(response.pagination)),
                  of(removeLoader(LOADER)),
                ),
              ),
              catchError(err => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: err.params,
                      message: err.error,
                      status: err.status,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const searchParametersEffect = combineEpics(
  getSearchResultsFromPageChangeEffect,
  sendSearchAnalyticsFromVehicleTagChangeEffect,
  getRangesFromSearchParametersEffect,
  setLeaseTypeFromSearchParametersEffect,
  sendSearchAnalyticsEffect,
  removeVehicleRangesFromSearchParametersEffect,
  saveSearchEffect,
  getSearchResultsFromAiEffect,
)
