import currency from 'currency.js'

import {
  constants,
  FILTER_BODY_STYLES,
  FILTER_BOOT_SPACE,
  FILTER_CAPACITY,
  FILTER_CAPACITY_LOAD_HEIGHT,
  FILTER_CAPACITY_LOAD_LENGTH,
  FILTER_CAPACITY_LOAD_WIDTH,
  FILTER_CAPACITY_PAYLOAD,
  FILTER_DELIVERY,
  FILTER_DOORS,
  FILTER_DRIVE_TYPES,
  FILTER_ELECTRIC_BATTERY_CAPACITY,
  FILTER_ELECTRIC_FASTEST_CHARGE_RATE,
  FILTER_ELECTRIC_HOME_CHARGE_TIME,
  FILTER_ELECTRIC_RANGE,
  FILTER_FEATURES,
  FILTER_FUEL_TYPES,
  FILTER_IN_STOCK,
  FILTER_INCLUSIVE_BENEFITS,
  FILTER_INITIAL_PAYMENT,
  FILTER_LEASE_TYPES,
  FILTER_LEASE_TYPES_PERSONAL,
  FILTER_MANUFACTURERS,
  FILTER_MILEAGES,
  FILTER_MONTHLY_PAYMENT,
  FILTER_MONTHLY_PAYMENT_MAX,
  FILTER_MONTHLY_PAYMENT_MIN,
  FILTER_PERFORMANCE,
  FILTER_RANGES,
  FILTER_ROOF_HEIGHT,
  FILTER_SEATS,
  FILTER_SORT_BY,
  FILTER_SPEC,
  FILTER_TERMS,
  FILTER_TRANSMISSION_TYPES,
  FILTER_VEHICLE_TYPES,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
  FILTER_WHEELBASE,
} from '../constants'
import { FILTERS } from '../filters'

const vatConversion = constants.currentVatRate / 100
const vatDecimal = vatConversion + 1

export const addVatToPrice = (value, decimal = 2) =>
  Number((Number(value) * vatDecimal).toFixed(decimal))

export const removeVatFromPrice = value => Number((Number(value) / vatDecimal).toFixed(2))

export const calculateAverageMonthlyCost = (
  initialPaymentInMonths,
  term,
  arrangementFee,
  monthlyCost,
) => {
  const totalMonths = initialPaymentInMonths + (term - 1)
  const totalMonthCost = totalMonths * monthlyCost
  const totalCost = totalMonthCost + arrangementFee

  return (totalCost / term).toFixed(2)
}

export const calculateTotalDealCost = deal => {
  const totalMonths = deal.initialPaymentInMonths + (deal.term - 1)
  const totalCost =
    (totalMonths * !!deal.monthlyPayment ? deal.monthlyPayment : deal.price) +
    (deal.documentFee ? deal.documentFee : deal.arrangementFee)

  return totalCost.toFixed(2)
}

export const formatPrice = value => currency(value, { symbol: '£' }).format()

export const formatPriceInPence = value => `${(value * 100).toFixed(1)}p`

export const formatPriceWithNoSymbol = value => {
  let parts = value.toString().split('.')
  parts[0] = parts[0].replace(constants.regExp.addCommasToNumbers, ',')
  return parts.join('.')
}

export const formatWithCommas = value =>
  value ? value.toString().replace(constants.regExp.addCommasToNumbers, ',') : 0

export const driveTypeAbbreviation = driveType => {
  switch (driveType) {
    case 'Front Wheel Drive':
      return 'FWD'
    case 'Rear Wheel Drive':
      return 'RWD'
    case 'Four Wheel Drive':
      return '4WD'
    default:
      return driveType
  }
}

export const fuelTypeAbbreviation = fuelType => {
  switch (fuelType) {
    case 'Petrol/LPG':
      return 'Petrol LPG'
    case 'Petrol/PlugIn Elec Hybrid':
      return 'Petrol PHEV'
    case 'Petrol/Electric Hybrid':
    case 'Diesel/Electric Hybrid':
      return 'Hybrid'
    case 'Diesel/PlugIn Elec Hybrid':
      return 'Diesel PHEV'
    default:
      return fuelType
  }
}

export const transmissionTypeAbbreviation = transmission => {
  switch (transmission) {
    case 'Automatic':
      return 'Auto'
    default:
      return transmission
  }
}

export const prettyNameForFilter = (filter, vehicleType) => {
  const isVan = vehicleType === FILTER_VEHICLE_TYPES_VANS
  const isCar = vehicleType === FILTER_VEHICLE_TYPES_CARS

  switch (filter) {
    case 'fueltype':
    case 'fuelTypes':
      return 'Fuel'
    case 'transmission':
    case 'transmissionTypes':
      return 'Drive'
    case isCar && 'bodystyle':
    case isCar && 'bodyStyles':
      return 'Style'
    case isVan && 'bodystyle':
    case isVan && 'bodyStyles':
      return 'Type'
    case isCar && 'spec':
      return 'Spec'
    case isVan && 'spec':
      return 'Features'
    case 'manufacturer':
    case 'manufacturers':
      return 'Make'
    case 'model':
    case 'ranges':
      return 'Model'
    case FILTER_PERFORMANCE:
      return 'Engine'
    case FILTER_DOORS:
      return 'Doors'
    case FILTER_SEATS:
      return 'Seats'
    case FILTER_FEATURES:
      return 'Features'
    case FILTER_WHEELBASE:
      return 'Size'
    case FILTER_CAPACITY:
      return 'Capacity'
    case FILTER_CAPACITY_PAYLOAD:
      return 'Payload'
    case FILTER_CAPACITY_LOAD_HEIGHT:
      return 'Load Height'
    case FILTER_CAPACITY_LOAD_LENGTH:
      return 'Load Length'
    case FILTER_CAPACITY_LOAD_WIDTH:
      return 'Load Width'
    case FILTER_INCLUSIVE_BENEFITS:
      return 'Inclusive Benefits'
    default:
      return filter
  }
}

export const subFilterPrettyName = name => {
  switch (name) {
    case 'Alloys':
      return 'All Alloys'
    case '14':
    case '15':
    case '16':
    case '17':
    case '18':
    case '19':
    case '20':
    case '21':
    case '22':
    case '23':
    case '24':
    case '25':
    case '26':
      return `${name} Inch`
    case 'Parking Sensors':
    case 'Heated Seats':
      return 'Front & Rear'
    case 'Rear parking sensor':
    case 'Heated rear seats':
      return 'Rear'
    case 'Front parking sensor':
    case 'Heated front seat':
      return 'Front'
    default:
      return name
  }
}

export const performanceTypePrettyName = name => {
  switch (name) {
    case 'MIN_MPG':
      return 'MPG'
    case 'MAX_MPH062':
      return '0 to 62'
    case 'MAX_ENGINE':
      return 'Max Size'
    case 'MIN_ENGINE':
      return 'Min Size'
    default:
      return name
  }
}

export const performanceValuePrettyName = (name, value) => {
  switch (name) {
    case 'MAX_MPH062':
      return `Up to ${value}s`
    case 'MIN_MPG':
      return `${value}mpg+`
    case 'MIN_ENGINE':
    case 'MAX_ENGINE':
      return `${value}L`
    case 'MAX_CO2':
      return `Up to ${value}g/km`
    default:
      return value
  }
}

export const isFilterAvailable = (availableFilters, filterType, selectedFilter) =>
  availableFilters[filterType].filter(filter => filter.id === selectedFilter).length === 1

export const getFilterImage = (name, filterName) =>
  `${name.toLowerCase().split(' ').join('-').replace('/', '').replace('(', '').replace(')', '')}.${
    filterName === FILTER_MANUFACTURERS ? 'png' : 'svg'
  }`

export const getURLReadyFilterName = filter =>
  filter
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase()

export const getTermsFilterName = label => {
  switch (label) {
    case 12:
      return '12 months'
    case 18:
      return '18 months'
    case 24:
      return '24 months'
    case 36:
      return '36 months'
    case 48:
      return '48 months'
    case 60:
      return '60 months'
    default:
      return label
  }
}

export const getTermsAsYearsName = label => {
  switch (label) {
    case 12:
      return '1'
    case 18:
      return '1.5'
    case 24:
      return '2'
    case 36:
      return '3'
    case 48:
      return '4'
    case 60:
      return '5'
    default:
      return label
  }
}

export const getMileagesFilterName = label => {
  switch (label) {
    case 5000:
      return '5k'
    case 6000:
      return '6k'
    case 8000:
      return '8k'
    case 10000:
      return '10k'
    case 12000:
      return '12k'
    case 15000:
      return '15k'
    case 20000:
      return '20k'
    case 25000:
      return '25k'
    case 30000:
      return '30k'
    case 35000:
      return '35k'
    default:
      return label
  }
}

export const filterIsDefaultValue = (filterType, name) => {
  const combinedValue = `${filterType}-${name}`

  switch (combinedValue) {
    case `${FILTER_MONTHLY_PAYMENT_MAX}-9999999`:
      return true
    default:
      return false
  }
}

export const filterLabelPrettyName = (filterType, name, typeName, isAbbreviated) => {
  switch (filterType) {
    case FILTER_LEASE_TYPES:
      if (parseInt(name) === FILTER_LEASE_TYPES_PERSONAL) {
        return 'Personal'
      } else {
        return 'Business'
      }
    case FILTER_VEHICLE_TYPES:
      if (parseInt(name) === FILTER_VEHICLE_TYPES_CARS) {
        return 'Cars'
      } else {
        return 'Vans'
      }
    case FILTER_MONTHLY_PAYMENT_MIN:
    case FILTER_MONTHLY_PAYMENT_MAX:
      if (name !== 9999999) {
        return formatPrice(name).split('.')[0]
      } else {
        return undefined
      }
    case FILTER_MONTHLY_PAYMENT:
    case FILTER_INITIAL_PAYMENT:
      return formatPrice(name).split('.')[0]
    case FILTER_TERMS:
      if (typeof name === 'object') {
        return name
          .map(i =>
            Number(i) >= 24
              ? getTermsAsYearsName(Number(i)) + (isAbbreviated ? ' yrs' : ' years')
              : i + (isAbbreviated ? ' mos' : ' months'),
          )
          .join(', ')
      }

      return Number(name) >= 24
        ? getTermsAsYearsName(Number(name)) + (isAbbreviated ? ' yrs' : ' years')
        : name + (isAbbreviated ? ' mos' : ' months')
    case FILTER_MILEAGES:
      if (typeof name === 'object') {
        return name.map(i => formatWithCommas(i) + (isAbbreviated ? ' mi' : ' miles')).join(', ')
      }

      return `${formatWithCommas(name)} ${isAbbreviated ? 'mi' : 'miles'}`
    case FILTER_BOOT_SPACE:
      return `${name.endsWith('-') ? name.replace('-', '+') : name} litres`
    case FILTER_DOORS:
      return `${name} doors`
    case FILTER_SEATS:
      return `${name} seats`
    case FILTER_SORT_BY:
      return Object.values(constants.searchSortingOptions).find(i => i.value === parseInt(name))
        .label
    case FILTER_FUEL_TYPES:
      return fuelTypeAbbreviation(name)
    case FILTER_SPEC:
      return isAbbreviated
        ? subFilterPrettyName(name) === 'Front & Rear'
          ? 'F & R'
          : subFilterPrettyName(name)
        : name
    case FILTER_DRIVE_TYPES:
      return isAbbreviated
        ? driveTypeAbbreviation(name)
        : `${name} (${driveTypeAbbreviation(name)})`
    case FILTER_ROOF_HEIGHT:
      return isAbbreviated ? name : `${name} Roof`
    case FILTER_WHEELBASE:
      return isAbbreviated ? name : `${name} Wheelbase`
    case FILTER_PERFORMANCE:
      return performanceValuePrettyName(typeName, name)
    case FILTER_CAPACITY:
      switch (typeName) {
        case FILTER_CAPACITY_PAYLOAD:
          return `${convertToTonnes(name)}t`
        default:
          return `${convertToMetres(name)}m`
      }
    case FILTER_DELIVERY:
      switch (name) {
        case '<1m':
          return isAbbreviated ? '<1 mo' : 'Less than 1 month'
        case '>12m':
          return isAbbreviated ? '>12 mos' : 'More than 12 months'
        default:
          return name && `${name.replace('m', '')} ${isAbbreviated ? ' mos' : ' months'}`
      }
    case FILTER_IN_STOCK:
      if (name === 1) {
        return 'In stock'
      } else {
        return 'Factory order'
      }
    default:
      return name
  }
}

export const getElectricFilterLabelPrettyName = (
  filterType,
  name,
  typeName,
  isAbbreviated,
  position = 'middle',
) => {
  const min = name.split('-')[0]
  const max = name.split('-')[1]

  if (filterType === FILTER_ELECTRIC_RANGE) {
    return `${position === 'first' ? 'Up to ' : ''}${position === 'first' ? max : min}${position !== 'first' ? '+' : ''} miles`
  }

  if (filterType === FILTER_ELECTRIC_HOME_CHARGE_TIME) {
    const hours = parseInt(position === 'last' ? min : max) / 60

    return isAbbreviated
      ? `<${hours} hr${hours !== 1 ? 's' : ''}`
      : `${position !== 'last' ? 'Up to ' : ''}${hours}${position === 'last' ? '+' : ''} hour${hours !== 1 ? 's' : ''}`
  }

  if (
    filterType === FILTER_ELECTRIC_FASTEST_CHARGE_RATE ||
    filterType === FILTER_ELECTRIC_BATTERY_CAPACITY
  ) {
    if (typeName === 'max') {
      return `Up to ${max}kWh`
    }

    return `${min ? `${min}kWh` : 'Up'}${max ? ` to ${max}kWh` : '+'}`
  }
}

const getListOfNames = (list, filterName, ranges = null) => {
  const filterList = filterName === FILTER_RANGES ? ranges : FILTERS[filterName]

  const nameList = list.map(listItem => {
    const item = filterList.find(filter => listItem === filter.id)
    if (item) {
      return filterName === FILTER_FUEL_TYPES ? fuelTypeAbbreviation(item.name) : item.name
    }
    return ''
  })
  return nameList
}

export const convertToMetres = mm => Number(mm / 1000).toFixed(2)

export const convertToTonnes = kg => Number(kg / 1000).toFixed(1)

export const getTextValueForVehicleAnalytics = (searchParameters, ranges = null, tags = null) => {
  const textValueArray = [
    searchParameters.manufacturers.length > 0
      ? getListOfNames(searchParameters.manufacturers, FILTER_MANUFACTURERS)
      : null,
    searchParameters.ranges.length > 0 && ranges.length > 0
      ? getListOfNames(searchParameters.ranges, FILTER_RANGES, ranges)
      : null,
    searchParameters.vehicleTypes[0] === FILTER_VEHICLE_TYPES_VANS ? 'Van' : 'Car',
    searchParameters.fuelTypes.length > 0
      ? getListOfNames(searchParameters.fuelTypes, FILTER_FUEL_TYPES)
      : null,
    searchParameters.bodyStyles.length > 0
      ? getListOfNames(searchParameters.bodyStyles, FILTER_BODY_STYLES)
      : null,
    searchParameters.driveTypes.length > 0
      ? getListOfNames(searchParameters.driveTypes, FILTER_DRIVE_TYPES)
      : null,
    searchParameters.transmissionTypes.length > 0
      ? getListOfNames(searchParameters.transmissionTypes, FILTER_TRANSMISSION_TYPES)
      : null,
    searchParameters.doors.length > 0
      ? searchParameters.doors.map(door => filterLabelPrettyName(FILTER_DOORS, door))
      : null,
    searchParameters.seats.length > 0
      ? searchParameters.seats.map(seat => filterLabelPrettyName(FILTER_SEATS, seat))
      : null,
    searchParameters.searchTerm !== '' ? searchParameters.searchTerm : null,
    searchParameters.vehicleTags.length > 0 && tags.length > 0 ? tags.map(tag => tag.name) : null,
  ]
    .filter(item => item !== null)
    .reduce((total, newValue) => total.concat(newValue), [])

  return textValueArray
}

// This allows the budget filters to hydrate for saved searches saved prior to VAT removal fix
export const budgetPaymentFix = value => {
  switch (value) {
    case 167:
    case 292:
    case 417:
    case 542:
    case 667:
    case 792:
    case 1667:
    case 2917:
    case 4167:
      return `${value - 1}.67`
    case 208:
    case 333:
    case 458:
    case 583:
    case 708:
    case 833:
    case 2083:
    case 3333:
      return `${value}.33`
    case 100:
    case 125:
    case 150:
    case 200:
    case 250:
    case 300:
    case 350:
    case 375:
    case 400:
    case 450:
    case 500:
    case 550:
    case 600:
    case 625:
    case 650:
    case 700:
    case 750:
    case 800:
    case 850:
    case 900:
    case 950:
    case 1000:
    case 1250:
    case 1500:
    case 2000:
    case 2500:
    case 3000:
    case 3500:
    case 3750:
    case 4000:
    case 4500:
    case 5000:
      return `${value}.00`
    default:
      return value
  }
}

export const moveItemInArray = (array, from, to) => {
  if (to === from) {
    return array
  }

  let target = array[from]
  let increment = to < from ? -1 : 1

  for (let k = from; k !== to; k += increment) {
    array[k] = array[k + increment]
  }
  array[to] = target

  return array
}

export const capitaliseFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getRatingText = rating => {
  let copy = ''

  if (rating >= 0 && rating <= 69) {
    copy = 'Low'
  } else if (rating >= 70 && rating <= 79) {
    copy = 'Mid'
  } else if (rating >= 80 && rating <= 89) {
    copy = 'Hot'
  } else if (rating >= 90) {
    copy = 'Loco'
  }

  return copy
}

export const getRatingAdjective = rating => {
  let copy = ''

  if (rating >= 0 && rating <= 69) {
    copy = 'Decent'
  } else if (rating >= 70 && rating <= 79) {
    copy = 'Good'
  } else if (rating >= 80 && rating <= 89) {
    copy = 'Great'
  } else if (rating >= 90) {
    copy = 'Amazing'
  }

  return copy
}

export const filterClassName = (className) => {
  return className
    .toLowerCase()
    .replace(/\s+|\(|\)|\+|\//g, '-')
    .replace(/,/g, '')
    .replace(/</g, 'lt-')
    .replace(/>/g, 'gt-')
}
