import equal from 'fast-deep-equal'
import { combineEpics, ofType } from 'redux-observable'
import { concat, EMPTY, from, of } from 'rxjs'
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'

import {
  constants,
  FILTER_DELIVERY,
  FILTER_GROUPING,
  FILTER_IN_STOCK,
  FILTER_INCLUSIVE_BENEFITS,
  FILTER_INITIAL_PAYMENT,
  FILTER_INITIAL_PAYMENT_MAX,
  FILTER_INITIAL_PAYMENT_MIN,
  FILTER_LEASE_TYPES,
  FILTER_MILEAGES,
  FILTER_MONTHLY_PAYMENT,
  FILTER_MONTHLY_PAYMENT_MAX,
  FILTER_MONTHLY_PAYMENT_MIN,
  FILTER_SORT_BY,
  FILTER_TERMS,
  FILTER_VEHICLE_TAGS,
} from '@/lib/constants'
import { ERRORS } from '@/lib/errors'
import { LOADERS } from '@/lib/loaders'
import { getFiltersService } from '@/lib/services/searchService'
import { addError } from '@/store/reducers/error'
import { addLoader, removeLoader } from '@/store/reducers/loader'
import {
  clearAllSearchState,
  clearSearchParameters,
  clearSearchStateOnVehicleTypeChange,
  clearSelectedSearchFilter,
  openSearchFilter,
  storeGroupedVehiclePriceTag,
  storePageNumber,
  storeSearchFilter,
  storeSearchMultiParameters,
  storeSearchParameters,
  storeUngroupedVehiclePriceTag,
  toggleGroupedVehiclePriceTag,
} from '@/store/reducers/search'
import { selectLoaderExists } from '@/store/selectors/loader'
import { selectSearchParameters } from '@/store/selectors/search'

export const refreshSearchFiltersEffect = (action$, state$) => {
  const isOnExclusionList = filter =>
    filter === FILTER_DELIVERY ||
    filter === FILTER_TERMS ||
    filter === FILTER_MILEAGES ||
    filter === FILTER_IN_STOCK ||
    filter === FILTER_GROUPING ||
    filter === FILTER_MONTHLY_PAYMENT ||
    filter === FILTER_INITIAL_PAYMENT ||
    filter === FILTER_SORT_BY ||
    filter === FILTER_INITIAL_PAYMENT_MIN ||
    filter === FILTER_INITIAL_PAYMENT_MAX ||
    filter === FILTER_MONTHLY_PAYMENT_MAX ||
    filter === FILTER_MONTHLY_PAYMENT_MIN ||
    filter === FILTER_LEASE_TYPES ||
    filter === FILTER_VEHICLE_TAGS ||
    filter === FILTER_INCLUSIVE_BENEFITS

  return action$.pipe(
    ofType(
      storeSearchParameters,
      storeSearchMultiParameters,
      clearSearchParameters,
      clearAllSearchState,
      clearSelectedSearchFilter,
      clearSearchStateOnVehicleTypeChange,
      toggleGroupedVehiclePriceTag,
      storeGroupedVehiclePriceTag,
      storeUngroupedVehiclePriceTag,
    ),
    debounceTime(constants.searchDebounceDelay),
    startWith(state$),
    withLatestFrom(state$),
    pairwise(),
    switchMap(([prev, [action, curr]]) => {
      const isAlreadyLoading = selectLoaderExists(curr, LOADERS.fetchingSearchResults)

      if (isAlreadyLoading.length > 0) {
        return EMPTY
      }

      if (action.type === clearAllSearchState.type) {
        return of(storePageNumber(1))
      }

      const isFirstChange = 'value' in prev
      const previousVersionOfSearchParameters = isFirstChange
        ? prev.value.search.searchParameters
        : prev[1].search.searchParameters

      // Compare previous and current state
      if (!equal(previousVersionOfSearchParameters, curr.search.searchParameters)) {
        // Get filters from action payload
        const filters =
          action.payload?.visibleFilters && action.payload.visibleFilters.length > 0
            ? action.payload.visibleFilters
            : []

        if (filters.length === 0) {
          return of(storePageNumber(1))
        }

        return concat(
          of(storePageNumber(1)),
          from(filters).pipe(
            concatMap(filter => {
              const filterName = filter.split('-')[0].trim()

              if (isOnExclusionList(filterName)) {
                return EMPTY
              }

              return concat(
                of(addLoader(LOADERS.fetchingSearchFilter[filterName])),
                from(
                  getFiltersService({
                    filter: filterName,
                    search: selectSearchParameters(curr),
                  }),
                ).pipe(
                  concatMap(response =>
                    concat(
                      of(
                        storeSearchFilter({
                          filter: filterName,
                          params: response,
                        }),
                      ),
                      of(removeLoader(LOADERS.fetchingSearchFilter[filterName])),
                    ),
                  ),
                  catchError(() =>
                    concat(
                      of(removeLoader(LOADERS.fetchingSearchFilter[filterName])),
                      of(removeLoader(LOADERS.fetchingSearchResults)),
                      of(
                        addError({
                          key: ERRORS.fetchingSearchResults,
                          message: constants.errorMessages.fetchingDeals,
                        }),
                      ),
                    ),
                  ),
                ),
              )
            }),
          ),
        )
      } else {
        return EMPTY
      }
    }),
  )
}

export const openSearchFilterEffect = (action$, state$) => {
  const isOnExclusionList = specificFilter =>
    specificFilter === FILTER_INCLUSIVE_BENEFITS ||
    specificFilter === FILTER_DELIVERY ||
    specificFilter === FILTER_TERMS ||
    specificFilter === FILTER_MILEAGES ||
    specificFilter === FILTER_MONTHLY_PAYMENT ||
    specificFilter === FILTER_INITIAL_PAYMENT ||
    specificFilter === FILTER_SORT_BY

  return action$.pipe(
    ofType(openSearchFilter),
    map(action => action.payload),
    filter(payload => !isOnExclusionList(payload)),
    switchMap(filter => {
      const filterName = filter.split('-')[0]
      const search = selectSearchParameters(state$.value)
      const LOADER = LOADERS.fetchingSearchFilter[filterName]

      return concat(
        of(addLoader(LOADER)),
        from(getFiltersService({ filter: filterName, search })).pipe(
          switchMap(response =>
            concat(
              of(
                storeSearchFilter({
                  filter: filterName,
                  params: response,
                }),
              ),
              of(removeLoader(LOADER)),
            ),
          ),
        ),
      )
    }),
  )
}

export const searchFiltersEffect = combineEpics(openSearchFilterEffect, refreshSearchFiltersEffect)
