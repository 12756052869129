import TagManager from 'react-gtm-module'

import {
  constants,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
  LEASE_PROFILE_FILTER_INITIAL_PAYMENT_IN_MONTHS,
  LEASE_PROFILE_FILTER_STOCK_STATUS,
} from '../constants'
import { capitaliseFirstLetter } from './search'

export const scrollToTop = () => window.scrollTo(0, 0)

export const isLeaseProfileFilterAvailable = (availableFilters, filterType, selectedFilter) =>
  availableFilters[filterType].filter(filter => filter === selectedFilter).length === 1

export const isLeaseProfileOptionAvailable = (availableFilters, filterType, selectedFilter) => {
  return (
    availableFilters[`available${capitaliseFirstLetter(filterType)}`].filter(filter => {
      const filterName =
        filterType === LEASE_PROFILE_FILTER_INITIAL_PAYMENT_IN_MONTHS ||
        filterType === LEASE_PROFILE_FILTER_STOCK_STATUS
          ? filter[filterType]
          : filter[filterType.slice(0, -1)]

      return filterName === selectedFilter
    }).length === 1
  )
}

export const getVehicleHeroImage = item => {
  return item
    ? encodeURI(
        `${constants.imageHost.vehicle}/${constants.vehicleImageSizes.DISPLAY}/${item
          .split(' ')
          .join('-')
          .toLowerCase()}`,
      )
    : constants.defaultVehicleHeroImage.CAR
}

export const getGalleryImage = imagePath => {
  return encodeURI(`${constants.imageHost.vehicleImage}/${imagePath}`)
}

export const getSupplierImage = id => `${constants.imageHost.supplier}/logo/${id}/logo-square.png`

export const useDefaultSupplierImage = () => `${constants.imageHost.supplier}/logo/default.png`

export const valueScoreMessage = rating => {
  let message = ''

  if (rating === 100) {
    message = 'OMG this deal is seriously on fire. Super rare and should be snapped up fast.'
  } else if (rating >= 90) {
    message =
      "This means it's a super hot deal and fantastic value. And if you can find a stock deal in the 90s, it’s likely to sell out incredibly fast."
  } else if (rating >= 80) {
    message =
      'Anything in the 80s currently represents amazing value. Stock deals can be found in the 80s but will likely sell out fast.'
  } else if (rating >= 70) {
    message =
      '70+ was an A in school, and still represents good value against PCP prices. Stock deals will still sell out quickly given current supply shortages.'
  } else if (rating >= 60) {
    message =
      'Deals in the 60s are not hot deals but depending on the make and model, can still be strong deals, so don’t shy away if this is the car for you.'
  } else {
    message =
      "Deals below 60 are considered by us to be poor. Save the car to your garage and we'll tell you when it gets hotter."
  }

  return message
}

export const getUrlReadyString = param =>
  param
    .toLowerCase()
    .split(' ')
    .join('-')
    .replace(constants.regExp.findSpecialCharacters, '')
    .replace(constants.regExp.findPlus, '-plus')

export const addToDataLayer = eventData => TagManager.dataLayer({ dataLayer: eventData })

export const trackContentSquarePageEvent = eventName => {
  /* eslint-disable */
  window._uxa = window._uxa || []
  window._uxa.push(['trackPageEvent', eventName])
  /* eslint-enable */
}

export const ordinalSuffix = number => {
  const j = number % 10
  const k = number % 100

  if (j === 1 && k !== 11) {
    return 'st'
  }

  if (j === 2 && k !== 12) {
    return 'nd'
  }

  if (j === 3 && k !== 13) {
    return 'rd'
  }

  return 'th'
}

export const calculateUpfront = ({ months, price }) => months * price

export const calculateTotalUpfront = ({ months, price, arrangementFee }) => {
  const monthsAtPrice = months * price
  return monthsAtPrice + arrangementFee
}

export const isVehicleOnComparisonList = (vehicleId, list) =>
  !!list.find(vehicle => vehicle.id === vehicleId)

export const getLabelForTopDeal = label => {
  let compiledLabel = 'View '

  if (label.includes('In Stock')) {
    compiledLabel += 'Stock '
  }

  if (label.includes('Factory Order')) {
    compiledLabel += 'Hottest '
  }

  if (label.includes('SUV')) {
    compiledLabel += label
      .replace('In Stock ', '')
      .replace(')', ' ')
      .split('(')
      .reverse()
      .join('')
      .replace('SUV', 'SUVs')
      .replace('Factory Order ', ' ')
  } else {
    compiledLabel += `${label.replace('Factory Order ', ' ').replace('In Stock ', ' ')}s`
  }

  if (label.includes('Sponsored')) {
    compiledLabel = 'View Sponsored Deal'
  }

  return compiledLabel
}

export const rangeForElectricVehicle = options => {
  if (options?.[399] && options[399].categoryId === 100) {
    return options[399]
  }

  if (options?.[400] && options[400].categoryId === 100) {
    return options[400]
  }

  if (options?.[401] && options[401].categoryId === 100) {
    return options[401]
  }

  if (options?.[403] && options[403].categoryId === 100) {
    return options[403]
  }

  if (options?.[404] && options[404].categoryId === 100) {
    return options[404]
  }

  if (options?.[405] && options[405].categoryId === 100) {
    return options[405]
  }

  if (options?.[403] && options[403].categoryId === 119) {
    return options[403]
  }

  if (options?.[404] && options[404].categoryId === 119) {
    return options[404]
  }

  if (options?.[405] && options[405].categoryId === 119) {
    return options[405]
  }

  return 0
}

export const fuelConsumptionValue = options => {
  if (options?.[188]) {
    return options[188]
  }

  if (options?.[198]) {
    return options[198]
  }

  if (options?.[197]) {
    return options[197]
  }

  if (options?.[342]) {
    return options[342]
  }

  if (options?.[344]) {
    return options[344]
  }

  if (options?.[11]) {
    return options[11]
  }

  if (options?.[290]) {
    return options[290]
  }

  if (options?.[291]) {
    return options[291]
  }

  if (options?.[292]) {
    return options[292]
  }

  if (options?.[344]) {
    return options[344]
  }

  if (options?.[345]) {
    return options[345]
  }

  if (options?.[346]) {
    return options[346]
  }

  return 0
}

export const fastestChargeTimeForElectricVehicle = options => {
  if (!!options[157] && options[157].displayValue !== 'N') {
    return options[157]
  }

  if (!!options[154] && options[154].displayValue !== 'N') {
    return options[154]
  }

  if (!!options[151] && options[151].displayValue !== 'N') {
    return options[151]
  }

  if (!!options[148] && options[148].displayValue !== 'N') {
    return options[148]
  }

  return 0
}

export const getChargerType = charge => {
  if (charge > 0 && charge < 3) {
    return 'Wall plug/3-pin plug'
  }

  if (charge >= 3 && charge <= 7.4) {
    return 'Installed home charger'
  }

  if (charge > 7.4 && charge <= 22) {
    return 'Fast charger'
  }

  if (charge > 22) {
    return 'Rapid charger'
  }
}

export const getCo2Value = options => {
  if (options?.[173]) {
    return options[173]
  }

  if (options?.[182]) {
    return options[182]
  }

  if (options?.[183]) {
    return options[183]
  }

  if (options?.[285]) {
    return options[285]
  }

  if (options?.[286]) {
    return options[286]
  }

  if (options?.[67]) {
    return options[67]
  }

  if (options?.[239]) {
    return options[239]
  }

  if (options?.[240]) {
    return options[240]
  }

  if (options?.[241]) {
    return options[241]
  }

  if (options?.[272]) {
    return options[272]
  }

  if (options?.[273]) {
    return options[273]
  }

  if (options?.[274]) {
    return options[274]
  }

  return {
    displayValue: 'N/A',
  }
}

export const generateVehicleLink = (vehiclePrice, suffix) => {
  const {
    leaseType,
    term,
    mileage,
    initialPaymentInMonths,
    stockStatus,
    id: vehiclePriceId,
    vehicleType: type,
    manufacturerName,
    modelName,
    derivativeName,
    vehicleId: id,
  } = vehiclePrice

  const vehicleType = type === FILTER_VEHICLE_TYPES_CARS ? 'car' : 'van'

  return `/${vehicleType}-leasing/${getUrlReadyString(manufacturerName)}/${getUrlReadyString(
    modelName,
  )}/${getUrlReadyString(
    derivativeName,
  )}/${id}/${leaseType}-${term}-${mileage}-${initialPaymentInMonths}-${stockStatus}/${vehiclePriceId}/${suffix}`
}

export const litresToGallons = litres => litres / 3.78541

export const rangeValue = (options, fuelType) => {
  if (fuelType !== 1 && fuelType !== 2) {
    return false
  }

  if (fuelConsumptionValue(options) && options[24]?.displayValue) {
    return (
      litresToGallons(parseFloat(options[24].displayValue)) *
      parseFloat(fuelConsumptionValue(options).displayValue)
    ).toFixed(0)
  }

  switch (options) {
    case fuelConsumptionValue(options):
      return
    default:
      return 0
  }
}

export const generateEventPayloadForVehiclePrice = vehiclePrice => {
  return {
    textValueVehicle: [
      vehiclePrice.vehicleType === FILTER_VEHICLE_TYPES_VANS ? 'Van' : 'Car',
      vehiclePrice.manufacturerName,
      vehiclePrice.rangeName,
      vehiclePrice.derivativeName,
      vehiclePrice.fuelTypeName,
      vehiclePrice.bodyStyleName,
      vehiclePrice.driveTypeName,
      vehiclePrice.transmissionTypeName,
      `${vehiclePrice.doors} Doors`,
      `${vehiclePrice.seats} Seats`,
    ],
    averageMonthlyPayment: vehiclePrice.monthlyPaymentAverage,
    vehiclePriceTag: vehiclePrice.vehiclePriceTags,
    vehicleTag: vehiclePrice.vehicleTag,
    bodyStyle: [vehiclePrice.bodyStyle],
    driveType: [vehiclePrice.driveType],
    fuelType: [vehiclePrice.fuelType],
    manufacturer: [vehiclePrice.manufacturer],
    model: [vehiclePrice.model],
    range: [vehiclePrice.range],
    seats: [vehiclePrice.seats],
    stockStatus: [vehiclePrice.stockStatus],
    trim: [vehiclePrice.trim],
    transmissionType: [vehiclePrice.transmissionType],
    vehicleType: [vehiclePrice.vehicleType],
    vehicleId: [vehiclePrice.vehicleId],
    leaseType: [vehiclePrice.leaseType],
    term: [vehiclePrice.term],
    mileage: [vehiclePrice.mileage],
    initialPaymentInMonths: [vehiclePrice.initialPaymentInMonths],
    monthlyPayment: vehiclePrice.monthlyPayment,
    rating: vehiclePrice.rating,
    doors: [vehiclePrice.doors],
  }
}
