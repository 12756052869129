export const endpointKeys = {
  activePriceAlerts: '/price-alerts/list/active',
  advancedLandingPageDeal: '/ad/deal',
  allSavedVehicles: '/user/garage/list',
  allSavedEnquiries: '/user/enquiries',
  allSavedSearches: '/user/search',
  availableLeaseProfiles: '/search/available-lease-profile',
  bestDealsByBodyStyle: '/search/home/featured',
  bodyStyles: '/search/filters/bodystyles',
  bootSpace: '/search/filters/boot-space',
  capacity: '/search/filters/capacity',
  careers: '/careers',
  configuratorSearchRequest: '/search/vehicle-configurator',
  configuratorModelViews: '/analytics/vehicle-configurator/model',
  dealsForPriceAlerts: '/search/multi-lease-profile',
  doors: '/search/filters/doors',
  driveTypes: '/search/filters/drivetypes',
  electricRange: '/search/filters/electric-range',
  electricBatteryCapacity: '/search/filters/electric-battery-capacity',
  electricFastestChargeRate: '/search/filters/electric-fastest-charge-rate',
  electricHomeChargeTime: '/search/filters/electric-home-charge-time',
  experimentList: '/visitors/experiments/groups',
  expiredPriceAlerts: '/price-alerts/list/expired',
  faqArticles: '/faqs/list/articles',
  features: '/search/filters/features',
  fuelTypes: '/search/filters/fueltypes',
  historicalPricesFromLeaseProfile: '/deal/history/profile',
  manufacturers: '/search/filters/manufacturers',
  newsletterSignup: '/marketing/communication/sign-up',
  pageContent: '/page-content',
  pageContentComparisonTemplate: '/page-content/comparison-template',
  pageContentList: '/page-content/list',
  pcpComparison: '/pcp/calculate',
  performance: '/search/filters/performance',
  postAnalyticsEvent: `/locolytics/sweep`,
  ranges: '/search/filters/ranges',
  recommendations: '/recommendations',
  removePriceAlert: '/price-alerts/remove',
  removeEnquiry: '/user/enquiry/remove',
  removeSavedSearch: '/user/search/remove',
  requestLeadVerificationCode: '/lead/code/send',
  rewardsHistory: '/user/rewards/history',
  roofHeight: '/search/filters/roofheight',
  addPriceAlert: '/price-alerts/add',
  saveEnquiry: '/user/enquiry/send',
  saveRewardDetails: '/user/rewards/save',
  saveSearch: '/user/search/save',
  saveUserDetails: '/user/details/update',
  search: '/search/v2',
  searchWithAi: '/search/ai',
  searchWithPath: '/search/url-path',
  searchWithReference: '/search/reference',
  searchByLeaseProfile: '/search/lease-profile',
  searchForVehicleComparison: '/search/vehicle-comparison',
  seats: '/search/filters/seats',
  sendAlternativeLeadVerification: '/lead/code/confirm',
  sendFeedback: '/feedback/send',
  sendLeadDetails: '/lead/register',
  sendLeadVerification: '/lead/confirm',
  sendPcpLeadDetails: '/lead/register/pcp',
  sendPoorCreditLeadDetails: '/lead/register/poor-credit',
  spec: '/search/filters/spec',
  supplierList: '/supplier/list',
  terms: '/search/filters/terms',
  testimonials: '/testimonials',
  transmissionTypes: '/search/filters/transmissiontypes',
  trims: '/search/filters/trims',
  userDetails: '/user/details',
  userGetUserName: '/user/username',
  userMarketingPreferences: '/user/communication/preferences',
  userPriceAlertPreferences: '/user/preferences/price-alerts',
  userMarketingEmailPreferences: '/user/preferences/marketing/email',
  userMarketingSmsPreferences: '/user/preferences/marketing/sms',
  vehicle: '/vehicle',
  vehicleComparisonData: '/vehicle/comparison-data',
  vehiclePaint: '/vehicle/paint',
  vehicleReview: '/vehicle/review',
  vehicleTechnicalData: '/vehicle/technical-data',
  vehicleStandardOptions: '/vehicle/standard-options',
  verifyEnquiry: '/lead/verify',
  wheelbase: '/search/filters/wheelbase',
}
