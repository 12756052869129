import { FILTER_RANGES, FILTER_TRIMS } from '../constants'
import { endpointKeys } from '../endpoints'
import { ERRORS, LocoError } from '../errors'
import RestService from './restService'

/**
 * Submits request to search endpoint
 *
 * @param params
 * @param {Array<{ id: number, name: string }>} params.bodyStyles
 * @param {Array<{ id: number, name: string }>} params.doors
 * @param {Array<{ id: number, name: string }>} params.driveTypes
 * @param {Array<{ id: number, name: string }>} params.features
 * @param {Array<{ id: number, name: string }>} params.fuelTypes
 * @param {number} params.grouping
 * @param {0 | 1} params.inStock
 * @param {Array<number>} params.leaseTypes
 * @param {Array<{ id: number, name: string, vehicleTypes: Array<number> }>} params.manufacturers
 * @param {Array<number>} params.mileages
 * @param {Array<{id: number, name: string, type: string, typeName: string}>} params.performance
 * @param {Array<{id: number, name: string, total: number, status: any, manufacturer: number, manufacturerName: string}>} params.ranges
 * @param {Array<{id: number, name: "Low" | "Medium" | "High"}>} params.roofHeight
 * @param {string} params.searchTerm
 * @param {Array<{ id: number, name: string }>} params.seats
 * @param {number<1 | 2 | 3 | 4>} params.sortBy
 * @param {Array<{id: number, name: string, category: string, categoryId: number, subFilters: Array<SubFilters> }>} params.spec
 * @param {Array<number>} params.terms
 * @param {Array<{id: number, name, string}>} params.transmissionTypes
 * @param {Array<{vehiclePriceTagCategoryId: number, vehiclePriceTagIds: Array<number> }>} params.vehiclePriceTags
 * @param {Array<{id: number, name: string}>} params.vehicleTags
 * @param {Array<number>} params.vehicleTypes
 * @param {Array<{id: number, name: "Short" | "Medium" | "Long"}>} params.wheelbase
 * @returns {Promise<*>}
 * @throws {LocoError}
 */
export const submitSearchService = async params => {
  try {
    return await RestService.get(
      endpointKeys.search,
      {
        ...params,
        vehiclePriceTags: encodeURIComponent(JSON.stringify(params.vehiclePriceTags)),
      },
      undefined,
      {
        priority: 'high',
      },
    )
  } catch (error) {
    throw new LocoError({
      message: 'We were unable to submit a search request.',
      code: ERRORS.fetchingSearchResults,
      error,
      params,
    })
  }
}

export const getRangesForManufacturersService = async params => {
  const filteredParams = Object.assign(
    {},
    ...Object.entries(params)
      .filter(([key]) => key !== FILTER_TRIMS && key !== FILTER_RANGES)
      .map(([key, value]) => ({ [key]: value })),
  )

  return await RestService.get(endpointKeys.ranges, {
    ...filteredParams,
    vehiclePriceTags: encodeURIComponent(JSON.stringify(params.vehiclePriceTags)),
  })
}

export const getTrimsForRangesService = async params => {
  const filteredParams = Object.assign(
    {},
    ...Object.entries(params)
      .filter(([key]) => key !== FILTER_TRIMS)
      .map(([key, value]) => ({ [key]: value })),
  )

  return await RestService.get(endpointKeys.trims, {
    ...filteredParams,
    vehiclePriceTags: encodeURIComponent(JSON.stringify(params.vehiclePriceTags)),
  })
}

export const getFiltersService = async params => {
  return await RestService.get(
    endpointKeys[params.filter],
    {
      ...params.search,
      vehiclePriceTags: encodeURIComponent(JSON.stringify(params.search.vehiclePriceTags)),
    },
    undefined,
    {
      priority: 'low',
    },
  )
}

export const getSearchWithReferenceService = async params => {
  return await RestService.get(endpointKeys.searchWithReference, { ...params })
}

export const saveSearchService = async params => {
  return await RestService.post(endpointKeys.saveSearch, {
    ...params,
    vehiclePriceTags: JSON.stringify(params.vehiclePriceTags),
  })
}

export const getSearchWithPathService = async params => {
  return await RestService.get(endpointKeys.searchWithPath, { ...params })
}

export const getSearchByLeaseProfileService = async params => {
  return await RestService.get(endpointKeys.searchByLeaseProfile, { ...params })
}

/**
 * Gets PCP Price for Personal Lease Deal
 * @param {Object} params - Parameters
 * @param {number} params.term
 * @param {number} params.initialPaymentInMonths
 * @param {VehicleId} params.vehicleId
 * @param {number} params.mileage
 * @returns {Promise<{documentFee, initialPaymentInMonths, initialPaymentTotal, mileage, monthlyPayment, monthlyPaymentAverage, term}>}
 * @throws {LocoError}
 */
export const getPcpPricesForPersonalService = async params => {
  try {
    return await RestService.get(endpointKeys.pcpComparison, { ...params })
  } catch (error) {
    if (error.status === 404) {
      return null
    } else {
      throw new LocoError({
        message: 'We were unable to calculate PCP price for this vehicle',
        code: ERRORS.calculatingPersonalPcpPrice,
        error,
        params,
      })
    }
  }
}

export const getRecommendationsService = async params => {
  try {
    return await RestService.get(endpointKeys.recommendations, { ...params })
  } catch (error) {
    if (error.status === 404) {
      return null
    } else {
      throw new LocoError({
        message: 'We were unable to generate recommendations for this vehicle',
        code: ERRORS.fetchingRecommendations,
        error,
        params,
      })
    }
  }
}

export const getSearchWithAiService = async params => {
  return await RestService.get(endpointKeys.searchWithAi, {
    ...params,
  })
}

export const getDealsForPriceAlertService = async params => {
  try {
    return await RestService.get(endpointKeys.dealsForPriceAlerts, { ...params })
  } catch (error) {
    throw new LocoError({
      message: 'We were unable to find deals for this price alert',
      code: ERRORS.fetchingDealsForPriceAlerts,
      error,
      params,
    })
  }
}
