import { captureException } from '@sentry/nextjs'

import { constants } from '../constants'
import { InternalServerError } from '../errors'

const get = async (endpointKey, query, urlParams = {}, options = {}) => {
  try {
    return await fetchWithTimeout(
      getUrl(endpointKey, urlParams, query ? query : null),
      {},
      options,
    ).then(response => {
      return getResponse(response)
    })
  } catch (error) {
    if (error.status === 500) {
      captureException(
        InternalServerError('RestService GET Failure', {
          extra: {
            error,
            query,
            urlParams,
            endpointKey,
            generatedUrl: getUrl(endpointKey, urlParams, query ? query : null),
          },
        }),
      )
    } else {
      return getError(error)
    }
  }
}

const post = async (endpointKey, params, urlParams = {}) => {
  try {
    const response = await fetchWithTimeout(getUrl(endpointKey, urlParams), {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify(params),
    })
    return getResponse(response)
  } catch (error) {
    if (error.status === 500) {
      captureException(
        InternalServerError('RestService POST Failure', {
          extra: {
            error,
            urlParams,
            params,
            endpointKey,
            generatedUrl: getUrl(endpointKey, urlParams),
            body: JSON.stringify(params),
          },
        }),
      )
    } else {
      return getError(error)
    }
  }
}

const createQueryString = query => {
  const queryString = Object.keys(query)
    .filter(key => query[key] != null && query[key].length !== 0 && key !== 'search')
    .map(key => key + '=' + query[key])
    .join('&')

  let searchObject = {}

  const search = query.search
    ? Object.keys(query.search)
        .filter(key => query.search[key] != null && query.search[key].length !== 0)
        .map(key => (searchObject[key] = query.search[key]))
    : ''

  const searchQueryString = encodeURIComponent(JSON.stringify(searchObject))

  return !!queryString || !!search
    ? `?${queryString ? queryString : ''}${!!queryString && !!search ? '&' : ''}${
        search ? `search=${searchQueryString}` : ''
      }`
    : ''
}

const fetchWithTimeout = async (url, params, options = {}) => {
  let didTimeOut = false
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true
      reject({
        error: 'An error has occurred. The request has timed out.',
        errorCode: 'timeout',
        params: params,
      })
    }, constants.fetchTimeout)

    fetch(url, { ...params, ...options })
      .then(response => {
        return response
          .text()
          .then(data => ({
            data: data.length ? JSON.parse(data) : {},
            status: response.status,
          }))
          .then(res => {
            clearTimeout(timeout)
            if (!didTimeOut) {
              resolve({
                data: res.data,
                status: res.status,
              })
            }
          })
      })
      .catch(err => {
        if (didTimeOut) {
          return null
        } else {
          reject(err)
        }
      })
  })
}

const getUrl = (endpointKey, urlParams = {}, query = null) => {
  const serverUrl = `${process.env.NEXT_PUBLIC_API_URL}${endpointKey}${
    urlParams.length ? urlParams : ''
  }`
  const queryString = query ? createQueryString(query) : ''
  return `${serverUrl}${queryString}`
}

const getResponse = response => {
  if (response.status === 404) {
    const error = {
      error: "An error has occurred. What you're looking for cannot be found.",
      status: response.status,
      errorCode: 'notfound',
      params: response.status,
      serverError: response.data.errors,
    }
    throw error
  } else if (response.status < 200 || response.status >= 300) {
    const error = {
      error: 'An error has occurred at the API level',
      status: response.status,
      errorCode: response.data.type || 'api error',
      message: response.data.message,
      serverError: response.data.errors,
    }
    throw error
  } else {
    return response.data
  }
}

const getError = err => {
  if (!!err && !!err.errorCode) {
    throw err
  } else {
    const error = {
      error: 'An error has occurred',
      errorCode: 'error',
      params: err,
    }
    throw error
  }
}

const api = {
  fetchWithTimeout,
  createQueryString,
  getError,
  getResponse,
  get,
  post,
}

export default api
