export const FILTERS = {
  manufacturers: [
    { id: 39, name: 'Abarth', vehicleTypes: [1] },
    { id: 37, name: 'Alfa Romeo', vehicleTypes: [1] },
    { id: 49, name: 'Alpine', vehicleTypes: [1] },
    // {id: 24, name: "Aston Martin", vehicleTypes: [1]},
    { id: 1, name: 'Audi', vehicleTypes: [1] },
    // { id: 43, name: 'Bentley', vehicleTypes: [1] },
    { id: 2, name: 'BMW', vehicleTypes: [1] },
    { id: 50, name: 'BMW Alpina', vehicleTypes: [1] },
    { id: 79, name: 'BYD', vehicleTypes: [1] },
    // { id: 52, name: 'Chevrolet', vehicleTypes: [1] },
    { id: 22, name: 'Citroen', vehicleTypes: [1, 2] },
    { id: 67, name: 'CUPRA', vehicleTypes: [1] },
    { id: 38, name: 'Dacia', vehicleTypes: [1, 2] },
    { id: 18, name: 'DS', vehicleTypes: [1] },
    // {id: 41, name: "Ferrari", vehicleTypes: [1]},
    { id: 30, name: 'Fiat', vehicleTypes: [1, 2] },
    { id: 8, name: 'Ford', vehicleTypes: [1, 2] },
    { id: 58, name: 'Fuso', vehicleTypes: [2] },
    { id: 70, name: 'Genesis', vehicleTypes: [1] },
    // {id: 59, name: "Great Wall Motors", vehicleTypes: [2]},
    { id: 76, name: 'GWM Ora', vehicleTypes: [1] },
    { id: 17, name: 'Honda', vehicleTypes: [1] },
    { id: 21, name: 'Hyundai', vehicleTypes: [1] },
    { id: 75, name: 'INEOS', vehicleTypes: [1] },
    // {id: 28, name: "Infiniti", vehicleTypes: [1]},
    { id: 61, name: 'Isuzu', vehicleTypes: [2] },
    { id: 62, name: 'Isuzu Trucks', vehicleTypes: [2] },
    { id: 64, name: 'Iveco', vehicleTypes: [2] },
    { id: 89, name: 'Jaecoo', vehicleTypes: [1] },
    { id: 13, name: 'Jaguar', vehicleTypes: [1] },
    { id: 14, name: 'JEEP', vehicleTypes: [1] },
    { id: 84, name: 'KGM', vehicleTypes: [1, 2] },
    { id: 6, name: 'KIA', vehicleTypes: [1] },
    // {id: 31, name: "Lamborghini", vehicleTypes: [1]},
    { id: 36, name: 'Land Rover', vehicleTypes: [1, 2] },
    { id: 66, name: 'LEVC', vehicleTypes: [2] },
    { id: 29, name: 'Lexus', vehicleTypes: [1] },
    { id: 44, name: 'Lotus', vehicleTypes: [1] },
    { id: 63, name: 'Man', vehicleTypes: [2] },
    { id: 11, name: 'Maserati', vehicleTypes: [1] },
    { id: 65, name: 'Maxus', vehicleTypes: [2] },
    { id: 25, name: 'Mazda', vehicleTypes: [1] },
    { id: 10, name: 'Mercedes-Benz', vehicleTypes: [1, 2] },
    { id: 12, name: 'MG Motor UK', vehicleTypes: [1] },
    { id: 32, name: 'MINI', vehicleTypes: [1] },
    { id: 26, name: 'Mitsubishi', vehicleTypes: [1, 2] },
    { id: 27, name: 'Nissan', vehicleTypes: [1, 2] },
    { id: 83, name: 'OMODA', vehicleTypes: [1] },
    { id: 23, name: 'Peugeot', vehicleTypes: [1, 2] },
    { id: 57, name: 'Polestar', vehicleTypes: [1] },
    { id: 5, name: 'Porsche', vehicleTypes: [1] },
    { id: 9, name: 'Renault', vehicleTypes: [1, 2] },
    // {id: 40, name: "Rolls-Royce", vehicleTypes: [1]},
    { id: 16, name: 'SEAT', vehicleTypes: [1] },
    { id: 7, name: 'SKODA', vehicleTypes: [1] },
    { id: 33, name: 'smart', vehicleTypes: [1] },
    { id: 19, name: 'Subaru', vehicleTypes: [1] },
    { id: 34, name: 'Suzuki', vehicleTypes: [1] },
    { id: 42, name: 'Tesla', vehicleTypes: [1] },
    { id: 20, name: 'Toyota', vehicleTypes: [1, 2] },
    { id: 15, name: 'Vauxhall', vehicleTypes: [1, 2] },
    { id: 3, name: 'Volkswagen', vehicleTypes: [1, 2] },
    { id: 4, name: 'Volvo', vehicleTypes: [1] },
  ],
  bodyStyles: [
    { id: 11, name: 'Convertible' },
    { id: 6, name: 'Coupe' },
    { id: 10, name: 'Crossover' },
    { id: 5, name: 'Estate' },
    { id: 3, name: 'Hatchback' },
    { id: 4, name: 'MPV' },
    { id: 2, name: 'Saloon' },
    { id: 12, name: 'Supermini' },
    { id: 15, name: 'SUV (Large)' },
    { id: 14, name: 'SUV (Medium)' },
    { id: 13, name: 'SUV (Small)' },
    { id: 16, name: 'Extended Frame Chassis Cab' },
    { id: 17, name: 'Double Cab Chassis' },
    { id: 18, name: 'Extended Frame Double Cab Chassis' },
    { id: 19, name: 'High Cube' },
    { id: 21, name: 'Dropside Tipper' },
    { id: 24, name: 'Double Cab Luton' },
    { id: 27, name: 'Chassis Cab' },
    { id: 30, name: 'Fridge Van' },
    { id: 31, name: 'Long Cube' },
    { id: 32, name: 'High Volume/High Roof Van' },
    { id: 33, name: 'Medium Roof Window Van' },
    { id: 36, name: 'Luton' },
    { id: 38, name: 'High Roof Window Van' },
    { id: 39, name: 'Soft Top' },
    { id: 40, name: 'Pick-up' },
    { id: 41, name: 'Ex/Frame Dropside' },
    { id: 42, name: 'Ex/Frame Double Cab Dropside' },
    { id: 43, name: 'Box Van' },
    { id: 44, name: 'Tipper' },
    { id: 45, name: 'High Roof Minibus' },
    { id: 46, name: 'Van' },
    { id: 47, name: 'Window Van' },
    { id: 48, name: 'Fridge Box' },
    { id: 49, name: 'Standard Roof Minibus' },
    { id: 51, name: 'Crew Van' },
    { id: 52, name: 'Car Van' },
    { id: 53, name: 'Curtainside' },
    { id: 54, name: 'Mini-bus' },
  ],
  fuelTypes: [
    { id: 1, name: 'Diesel' },
    { id: 2, name: 'Petrol' },
    { id: 3, name: 'Petrol/PlugIn Elec Hybrid' },
    { id: 4, name: 'Petrol/Electric Hybrid' },
    { id: 5, name: 'Electric' },
    { id: 6, name: 'Diesel/PlugIn Elec Hybrid' },
    { id: 7, name: 'Diesel/Electric Hybrid' },
    { id: 8, name: 'Hydrogen' },
    { id: 10, name: 'Petrol/LPG' },
    { id: 1000, name: 'Hybrid' },
  ],
  driveTypes: [
    { id: 1, name: 'Four Wheel Drive' },
    { id: 2, name: 'Front Wheel Drive' },
    { id: 3, name: 'Rear Wheel Drive' },
  ],
  doors: [
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
  ],
  seats: [
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
  ],
  transmissionTypes: [
    { id: 1000, name: 'Automatic' },
    { id: 2000, name: 'Manual' },
  ],
  spec: [
    {
      id: 43,
      name: 'Alloys',
      categoryId: 2,
      category: 'Style',
      subFilters: [
        { id: 43015, name: '15' },
        { id: 43016, name: '16' },
        { id: 43017, name: '17' },
        { id: 43018, name: '18' },
        { id: 43019, name: '19' },
        { id: 43020, name: '20' },
        { id: 43021, name: '21' },
        { id: 43022, name: '22' },
        { id: 43023, name: '23' },
      ],
    },
    {
      id: 86,
      name: 'Apple Play / Android Auto',
      categoryId: 1,
      category: 'Convenience',
      subFilters: null,
    },
    { id: 75, name: 'Bluetooth', categoryId: 1, category: 'Convenience', subFilters: null },
    {
      id: 37,
      name: 'Climate Control',
      categoryId: 3,
      category: 'Comfort & Safety',
      subFilters: null,
    },
    {
      id: 101,
      name: 'Cruise Control',
      categoryId: 3,
      category: 'Comfort & Safety',
      subFilters: null,
    },
    { id: 84, name: 'DAB', categoryId: 1, category: 'Convenience', subFilters: null },
    {
      id: 100001,
      name: 'Heated Seats',
      categoryId: 3,
      category: 'Comfort & Safety',
      subFilters: [
        { id: 16, name: 'Heated Front Seat' },
        { id: 108, name: 'Heated Rear Seats' },
      ],
    },
    { id: 23, name: 'Isofix', categoryId: 3, category: 'Comfort & Safety', subFilters: null },
    {
      id: 100005,
      name: 'Leather Seats',
      categoryId: 3,
      category: 'Comfort & Safety',
      subFilters: [
        { id: 46, name: 'Full Leather' },
        { id: 47, name: 'Partial' },
        { id: 144, name: 'Alcantara' },
      ],
    },
    {
      id: 100002,
      name: 'Panoramic Roof',
      categoryId: 3,
      category: 'Comfort & Safety',
      subFilters: null,
    },
    {
      id: 80,
      name: 'Park Assist Camera',
      categoryId: 1,
      category: 'Convenience',
      subFilters: null,
    },
    {
      id: 100003,
      name: 'Parking Sensors',
      categoryId: 1,
      category: 'Convenience',
      subFilters: [
        { id: 77, name: 'Front Parking Sensor' },
        { id: 78, name: 'Rear Parking Sensor' },
      ],
    },
    { id: 9, name: 'Sat Nav', categoryId: 1, category: 'Convenience', subFilters: null },
    { id: 100004, name: 'Sunroof', categoryId: 3, category: 'Comfort & Safety', subFilters: null },
    {
      id: 134,
      name: 'Wireless Mobile Charging',
      categoryId: 1,
      category: 'Convenience',
      subFilters: null,
    },
  ],
  performance: [
    { id: 1, name: '10', type: 67, typeName: 'MAX_CO2' },
    { id: 2, name: '20', type: 67, typeName: 'MAX_CO2' },
    { id: 3, name: '30', type: 67, typeName: 'MAX_CO2' },
    { id: 4, name: '40', type: 67, typeName: 'MAX_CO2' },
    { id: 5, name: '50', type: 67, typeName: 'MAX_CO2' },
    { id: 6, name: '60', type: 67, typeName: 'MAX_CO2' },
    { id: 7, name: '70', type: 67, typeName: 'MAX_CO2' },
    { id: 8, name: '80', type: 67, typeName: 'MAX_CO2' },
    { id: 9, name: '90', type: 67, typeName: 'MAX_CO2' },
    { id: 10, name: '100', type: 67, typeName: 'MAX_CO2' },
    { id: 11, name: '110', type: 67, typeName: 'MAX_CO2' },
    { id: 12, name: '120', type: 67, typeName: 'MAX_CO2' },
    { id: 13, name: '130', type: 67, typeName: 'MAX_CO2' },
    { id: 14, name: '140', type: 67, typeName: 'MAX_CO2' },
    { id: 15, name: '150', type: 67, typeName: 'MAX_CO2' },
    { id: 16, name: '160', type: 67, typeName: 'MAX_CO2' },
    { id: 17, name: '170', type: 67, typeName: 'MAX_CO2' },
    { id: 18, name: '180', type: 67, typeName: 'MAX_CO2' },
    { id: 19, name: '190', type: 67, typeName: 'MAX_CO2' },
    { id: 20, name: '200', type: 67, typeName: 'MAX_CO2' },
    { id: 21, name: '210', type: 67, typeName: 'MAX_CO2' },
    { id: 22, name: '220', type: 67, typeName: 'MAX_CO2' },
    { id: 23, name: '230', type: 67, typeName: 'MAX_CO2' },
    { id: 24, name: '240', type: 67, typeName: 'MAX_CO2' },
    { id: 25, name: '250', type: 67, typeName: 'MAX_CO2' },
    { id: 26, name: '20', type: 11, typeName: 'MIN_MPG' },
    { id: 27, name: '25', type: 11, typeName: 'MIN_MPG' },
    { id: 28, name: '30', type: 11, typeName: 'MIN_MPG' },
    { id: 29, name: '35', type: 11, typeName: 'MIN_MPG' },
    { id: 30, name: '40', type: 11, typeName: 'MIN_MPG' },
    { id: 31, name: '45', type: 11, typeName: 'MIN_MPG' },
    { id: 32, name: '50', type: 11, typeName: 'MIN_MPG' },
    { id: 33, name: '55', type: 11, typeName: 'MIN_MPG' },
    { id: 34, name: '60', type: 11, typeName: 'MIN_MPG' },
    { id: 35, name: '65', type: 11, typeName: 'MIN_MPG' },
    { id: 36, name: '70', type: 11, typeName: 'MIN_MPG' },
    { id: 37, name: '3', type: 12, typeName: 'MAX_MPH062' },
    { id: 38, name: '4', type: 12, typeName: 'MAX_MPH062' },
    { id: 39, name: '5', type: 12, typeName: 'MAX_MPH062' },
    { id: 40, name: '6', type: 12, typeName: 'MAX_MPH062' },
    { id: 41, name: '7', type: 12, typeName: 'MAX_MPH062' },
    { id: 42, name: '8', type: 12, typeName: 'MAX_MPH062' },
    { id: 43, name: '9', type: 12, typeName: 'MAX_MPH062' },
    { id: 44, name: '10', type: 12, typeName: 'MAX_MPH062' },
    { id: 45, name: '1.0', type: 20, typeName: 'MIN_ENGINE' },
    { id: 46, name: '1.5', type: 20, typeName: 'MIN_ENGINE' },
    { id: 47, name: '2.0', type: 20, typeName: 'MIN_ENGINE' },
    { id: 48, name: '2.5', type: 20, typeName: 'MIN_ENGINE' },
    { id: 49, name: '3.0', type: 20, typeName: 'MIN_ENGINE' },
    { id: 50, name: '3.5', type: 20, typeName: 'MIN_ENGINE' },
    { id: 51, name: '4.0', type: 20, typeName: 'MIN_ENGINE' },
    { id: 52, name: '4.5', type: 20, typeName: 'MIN_ENGINE' },
    { id: 53, name: '5.0', type: 20, typeName: 'MIN_ENGINE' },
    { id: 54, name: '1.0', type: 21, typeName: 'MAX_ENGINE' },
    { id: 55, name: '1.5', type: 21, typeName: 'MAX_ENGINE' },
    { id: 56, name: '2.0', type: 21, typeName: 'MAX_ENGINE' },
    { id: 441, name: '11', type: 12, typeName: 'MAX_MPH062' },
    { id: 57, name: '2.5', type: 21, typeName: 'MAX_ENGINE' },
    { id: 442, name: '12', type: 12, typeName: 'MAX_MPH062' },
    { id: 58, name: '3.0', type: 21, typeName: 'MAX_ENGINE' },
    { id: 443, name: '13', type: 12, typeName: 'MAX_MPH062' },
    { id: 59, name: '3.5', type: 21, typeName: 'MAX_ENGINE' },
    { id: 444, name: '14', type: 12, typeName: 'MAX_MPH062' },
    { id: 60, name: '4.0', type: 21, typeName: 'MAX_ENGINE' },
    { id: 445, name: '15', type: 12, typeName: 'MAX_MPH062' },
    { id: 61, name: '4.5', type: 21, typeName: 'MAX_ENGINE' },
    { id: 446, name: '16', type: 12, typeName: 'MAX_MPH062' },
    { id: 62, name: '5.0', type: 21, typeName: 'MAX_ENGINE' },
    { id: 447, name: '17', type: 12, typeName: 'MAX_MPH062' },
    { id: 448, name: '18', type: 12, typeName: 'MAX_MPH062' },
    { id: 449, name: '19', type: 12, typeName: 'MAX_MPH062' },
    { id: 450, name: '20', type: 12, typeName: 'MAX_MPH062' },
  ],
  roofHeight: [
    { id: 20, name: 'Low' },
    { id: 21, name: 'Medium' },
    { id: 22, name: 'High' },
  ],
  wheelbase: [
    { id: 10, name: 'Short' },
    { id: 11, name: 'Medium' },
    { id: 12, name: 'Long' },
  ],
  features: [
    { id: 1, name: 'None', categoryId: 1 },
    { id: 2, name: 'Single', categoryId: 1 },
    { id: 3, name: 'Double', categoryId: 1 },
    { id: 101, name: 'None', categoryId: 2 },
    { id: 102, name: 'Dropside', categoryId: 2 },
    { id: 103, name: 'Tipper 1 Way', categoryId: 2 },
    { id: 104, name: 'Tipper 3 Way', categoryId: 2 },
    { id: 301, name: 'None', categoryId: 3 },
    { id: 302, name: 'Glazed', categoryId: 3 },
    { id: 303, name: 'Non Glazed', categoryId: 3 },
  ],
}
