import { compare } from 'compare-versions'
import UAParser from 'ua-parser-js'

import { constants, FILTER_VEHICLE_TYPES_VANS } from '../constants'

const parser = new UAParser()

export const { getUA, getBrowser, getDevice, getOS } = parser

export const isVariant = (userGroup, experimentName, number) =>
  userGroup === `${experimentName}-variant${number ? `-${number}` : ''}`

export const isProd = () =>
  process.env.NEXT_PUBLIC_ENVIRONMENT === constants.environments.PRODUCTION

export const isDevelopmentEnvironment = () =>
  process.env.NEXT_PUBLIC_ENVIRONMENT === constants.environments.DEVELOPMENT

export const getWidthOfWindow = () =>
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  )

export const getHeightOfWindow = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
  )

export const getCurrentYear = () => new Date().getFullYear()

// Feature-detecting localStorage ->
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
export function storageAvailable(type) {
  // ensures it returns false if server side rendered
  try {
    if (typeof window === 'undefined') {
      return false
    }
  } catch (e) {
    return false
  }

  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export const createUnixTimestampForCurrentTime = () => Math.round(new Date().getTime() / 1000)

export const createUnixTimestampForTime = time => Math.round(new Date(time).getTime() / 1000)

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) {
    return null
  }

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export function debounce(func, wait = 100) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

/* eslint-disable no-console */
export async function checkCacheStatus(currentVersion) {
  try {
    const res = await fetch('/meta.json')
    const { version: metaVersion } = await res.json()
    const areVersionsTheSame = compare(metaVersion || '0.0.0', currentVersion || '0.0.1', '=')

    if (storageAvailable('localStorage')) {
      window.localStorage.setItem(constants.localStorageKey.version, metaVersion)
    }

    if (!areVersionsTheSame) {
      console.log(`There is a new version (v${metaVersion}). Should force refresh.`)
      await refreshCacheAndReload()
    } else {
      console.log('There is no new version. No cache refresh needed.')
    }
  } catch (error) {
    console.log('An error occurred while checking cache status.', true)
    console.log(error, true)
  }
}

/* eslint-enable no-console */

/* eslint-disable no-console */
const refreshCacheAndReload = async () => {
  try {
    if (storageAvailable('localStorage') && !getWithExpiry('chunk_failed')) {
      setWithExpiry('chunk_failed', 'true', 10000)
      // Commented out for now until we can sort caching issue
      window.localStorage.removeItem(constants.localStorageKey.website)
      window.location.reload()
    }
  } catch (error) {
    console.log('An error occurred while deleting the cache.', true)
    console.log(error, true)
  }
}
/* eslint-enable no-console */

export const smsOperator = device => {
  switch (device) {
    case 'Android':
      return '?'
    case 'iOS':
    case 'Mac OS':
      return '&'
    default:
      return '?&'
  }
}

export const buildAnalyticsSearchParameters = params => {
  const newParams = {}

  Object.keys(params.searchRequest).map(item => {
    const last = item.charAt(item.length - 1)

    if (item.includes('vehiclePriceTags')) {
      return (newParams.vehiclePriceTag = [])
    }

    if (last === 's' && item !== 'doors' && item !== 'seats') {
      return (newParams[item.slice(0, -1)] = params.searchRequest[item])
    }

    if (item === 'inStock') {
      return (newParams.stockStatus = params.searchRequest.inStock ? [1] : [])
    }

    if (item.includes('PaymentMax') && params.searchRequest[item] === 9999999) {
      return null
    }

    if (item.includes('PaymentMin') && params.searchRequest[item] === 0) {
      return null
    }

    return (newParams[item] = params.searchRequest[item])
  })

  return newParams
}

export const buildAnalyticsData = deal => {
  return {
    textValueVehicle: [
      deal.vehicleType === FILTER_VEHICLE_TYPES_VANS ? 'Van' : 'Car',
      deal.manufacturerName,
      deal.rangeName,
      deal.derivativeName,
      deal.fuelTypeName,
      deal.bodyStyleName,
      deal.driveTypeName,
      deal.transmissionTypeName,
      `${deal.doors} Doors`,
      `${deal.seats} Seats`,
    ],
    averageMonthlyPayment: deal.monthlyPaymentAverage,
    vehiclePriceTag: deal.vehiclePriceTags,
    vehicleTag: deal.vehicleTag,
    bodyStyle: [deal.bodyStyle],
    driveType: [deal.driveType],
    fuelType: [deal.fuelType],
    manufacturer: [deal.manufacturer],
    model: [deal.model],
    range: [deal.range],
    seats: [deal.seats],
    stockStatus: [deal.stockStatus],
    trim: [deal.trim],
    transmissionType: [deal.transmissionType],
    vehicleType: [deal.vehicleType],
    vehicleId: [deal.vehicleId],
    leaseType: [deal.leaseType],
    term: [deal.term],
    mileage: [deal.mileage],
    initialPaymentInMonths: [deal.initialPaymentInMonths],
    monthlyPayment: deal.monthlyPayment,
    rating: deal.rating,
    doors: [deal.doors],
  }
}
