import { createSelector } from '@reduxjs/toolkit'

import {
  FILTER_CAPACITY,
  FILTER_INITIAL_PAYMENT_MAX_DEFAULT,
  FILTER_MONTHLY_PAYMENT,
  FILTER_MONTHLY_PAYMENT_MAX_DEFAULT,
  FILTER_PERFORMANCE,
} from '../../lib/constants'
import {
  addVatToPrice,
  filterLabelPrettyName,
  getTextValueForVehicleAnalytics,
} from '../../lib/utilities/search'

const getSearch = state => state.search

export const selectSearchUpdate = createSelector([getSearch], search => search)

export const selectIncludeVat = createSelector([getSearch], search =>
  search.searchParameters.leaseTypes.includes(2),
)

export const selectSearchResultDeals = createSelector([getSearch], search => search.deals)

export const selectVehicleRanges = createSelector([getSearch], search => search.ranges)

export const selectRangesForManufacturers = createSelector([getSearch], search => {
  const convertedRanges = []

  search.ranges.map(range => {
    return Object.assign(convertedRanges, { [range.id]: { ...range } })
  })

  return convertedRanges
})

export const selectSearchParameters = createSelector(
  [getSearch],
  search => !!search && search.searchParameters,
)

export const selectInStock = createSelector([selectSearchParameters], params => params.inStock)

export const selectGrouping = createSelector([selectSearchParameters], params => params.grouping)

export const selectCountOfSearchParameters = createSelector([selectSearchParameters], params => {
  const leadTimes =
    params.vehiclePriceTags.filter(tag => tag.vehiclePriceTagCategoryId === 3)[0] || []
  const inclusiveBenefits =
    params.vehiclePriceTags.filter(tag => tag.vehiclePriceTagCategoryId !== 3) || []

  const count =
    params.mileages.length +
    params.terms.length +
    params.fuelTypes.length +
    params.transmissionTypes.length +
    params.driveTypes.length +
    params.bodyStyles.length +
    params.spec.length +
    params.ranges.length +
    params.manufacturers.length +
    (!!params.monthlyPaymentMax && params.monthlyPaymentMax < 8000000 ? 1 : 0) +
    (!!params.monthlyPaymentMin && params.monthlyPaymentMin !== 0 ? 1 : 0) +
    (!!params.initialPaymentMax && params.initialPaymentMax < 8000000 ? 1 : 0) +
    (!!params.initialPaymentMin && params.initialPaymentMin !== 0 ? 1 : 0) +
    params.performance.length +
    (leadTimes.vehiclePriceTagIds ? leadTimes.vehiclePriceTagIds.length : 0) +
    inclusiveBenefits.length +
    (params.inStock ? 1 : 0) +
    params.doors.length +
    params.features.length +
    params.wheelbase.length +
    params.roofHeight.length +
    (params.payloadMin ? 1 : 0) +
    (params.payloadMax ? 1 : 0) +
    (params.loadHeightMin ? 1 : 0) +
    (params.loadHeightMax ? 1 : 0) +
    (params.loadLengthMin ? 1 : 0) +
    (params.loadLengthMax ? 1 : 0) +
    (params.loadWidthMin ? 1 : 0) +
    (params.loadWidthMax ? 1 : 0) +
    params.seats.length +
    (params.electricBatteryCapacity?.length || 0) +
    (params.electricHomeChargeTime?.length || 0) +
    (params.electricFastestChargeRate?.length || 0) +
    (params.electricRange?.length || 0) +
    (params.bootSpace?.length || 0) +
    (params.searchTerm ? 1 : 0) +
    (params.vehicleTags?.length || 0)

  return count
})

export const selectCountOfVehicleTags = createSelector(
  [selectSearchParameters],
  params => params.vehicleTags.length,
)

export const selectLeaseType = createSelector(
  [getSearch],
  search => search.searchParameters.leaseTypes[0],
)

export const selectAvailableFilters = createSelector([getSearch], search => search.availableFilters)

export const selectSearchTerm = createSelector(
  [getSearch],
  search => search.searchParameters.searchTerm,
)

export const selectVehicleTotal = createSelector([getSearch], search => search.vehicleTotal)

export const selectPricesTotal = createSelector([getSearch], search => search.pricesTotal)

export const selectResultsTotal = createSelector([getSearch], search => search.resultsTotal)

export const selectSearchTotals = createSelector([getSearch], search => {
  return {
    vehicleTotal: search.vehicleTotal,
    pricesTotal: search.pricesTotal,
    resultsTotal: search.resultsTotal,
  }
})

export const selectPagination = createSelector([getSearch], search => search.pagination)

export const selectMileagesSearchParams = createSelector(
  [selectSearchParameters],
  params => params.mileages,
)

export const selectAvailableMonthlyMin = createSelector(
  [selectAvailableFilters, selectIncludeVat],
  (filters, includeVat) =>
    Math.floor(
      filters.budget[0].minMonthly > filters.budget[0].maxMonthly
        ? 0
        : includeVat
          ? addVatToPrice(filters.budget[0].minMonthly)
          : filters.budget[0].minMonthly,
    ),
)

export const selectAvailableMonthlyMax = createSelector(
  [selectAvailableFilters, selectIncludeVat],
  (filters, includeVat) =>
    Math.ceil(
      filters.budget[0].maxMonthly < filters.budget[0].minMonthly
        ? FILTER_MONTHLY_PAYMENT_MAX_DEFAULT
        : includeVat
          ? addVatToPrice(filters.budget[0].maxMonthly)
          : filters.budget[0].maxMonthly,
    ),
)

export const selectAvailableInitialMin = createSelector(
  [selectAvailableFilters, selectIncludeVat],
  (filters, includeVat) =>
    Math.floor(
      filters.budget[0].minInitial > filters.budget[0].maxInitial
        ? 0
        : includeVat
          ? addVatToPrice(filters.budget[0].minInitial)
          : filters.budget[0].minInitial,
    ),
)

export const selectAvailableInitialMax = createSelector(
  [selectAvailableFilters, selectIncludeVat],
  (filters, includeVat) =>
    Math.ceil(
      filters.budget[0].maxInitial < filters.budget[0].minInitial
        ? FILTER_INITIAL_PAYMENT_MAX_DEFAULT
        : includeVat
          ? addVatToPrice(filters.budget[0].maxInitial)
          : filters.budget[0].maxInitial,
    ),
)

export const selectInitialPaymentMinSearchParams = createSelector(
  [selectSearchParameters],
  params => params.initialPaymentMin || 0,
)

export const selectActiveInitialPaymentMin = createSelector(
  [selectInitialPaymentMinSearchParams, selectIncludeVat],
  (params, includeVat) => Math.floor(includeVat ? addVatToPrice(params) : params),
)

export const selectInitialPaymentMaxSearchParams = createSelector(
  [selectSearchParameters],
  params => params.initialPaymentMax || 9999999,
)

export const selectActiveInitialPaymentMax = createSelector(
  [selectInitialPaymentMaxSearchParams, selectIncludeVat],
  (params, includeVat) => Math.ceil(includeVat ? addVatToPrice(params) : params),
)

export const selectMonthlyMaxSearchParams = createSelector(
  [selectSearchParameters],
  params => params.monthlyPaymentMax || 9999999,
)

export const selectActiveMonthlyMax = createSelector(
  [selectMonthlyMaxSearchParams, selectIncludeVat],
  (params, includeVat) => {
    if (params === 9999999) {
      return params
    }

    return Math.ceil(includeVat ? addVatToPrice(params) : params)
  },
)

export const selectMonthlyMinSearchParams = createSelector(
  [selectSearchParameters],
  params => params.monthlyPaymentMin,
)

export const selectActiveMonthlyMin = createSelector(
  [selectMonthlyMinSearchParams, selectIncludeVat, selectAvailableMonthlyMin],
  (params, includeVat, availableMin) =>
    params ? Math.floor(includeVat ? addVatToPrice(params) : params) : availableMin,
)

export const selectTermsSearchParams = createSelector(
  [selectSearchParameters],
  params => params.terms,
)

export const selectVehicleType = createSelector(
  [selectSearchParameters],
  params => params.vehicleTypes,
)

export const selectFuelTypesSearchFilter = createSelector([getSearch], search => search?.fuelTypes)

export const selectActiveFuelTypes = createSelector(
  [selectSearchParameters],
  params => params.fuelTypes,
)

export const selectFullActiveFuelTypes = createSelector(
  [selectActiveFuelTypes, selectFuelTypesSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selectedFuel =>
      search.map(fuel => (fuel.id === selectedFuel ? newArray.push(fuel) : null)),
    )
    return newArray
  },
)

export const selectTransmissionTypesSearchFilter = createSelector(
  [getSearch],
  search => search?.transmissionTypes,
)

export const selectActiveTransmissionTypes = createSelector(
  [selectSearchParameters],
  params => params.transmissionTypes,
)

export const selectFullActiveTransmissionTypes = createSelector(
  [selectActiveTransmissionTypes, selectTransmissionTypesSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectDriveTypesSearchFilter = createSelector([getSearch], search => search.driveTypes)

export const selectActiveDriveTypes = createSelector(
  [selectSearchParameters],
  params => params.driveTypes,
)

export const selectFullActiveDriveTypes = createSelector(
  [selectActiveDriveTypes, selectDriveTypesSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectBodyStylesSearchFilter = createSelector([getSearch], search => search.bodyStyles)

export const selectActiveBodyStyles = createSelector(
  [selectSearchParameters],
  params => params.bodyStyles,
)

export const selectFullActiveBodyStyles = createSelector(
  [selectActiveBodyStyles, selectBodyStylesSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectPerformanceSearchFilter = createSelector(
  [getSearch],
  search => search.performance,
)

export const selectActivePerformance = createSelector(
  [selectSearchParameters],
  params => params.performance,
)

export const selectFullActivePerformance = createSelector(
  [selectActivePerformance, selectPerformanceSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectSpecSearchFilter = createSelector([getSearch], search => search.spec)

export const selectSpecSearchFilterByCategoryName = (state, categoryName) =>
  state.search.spec.filter(i => i.category === categoryName)

export const selectActiveSpec = createSelector([selectSearchParameters], params => params.spec)

export const selectFullActiveSpec = createSelector(
  [selectActiveSpec, selectSpecSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item =>
        item.id === selected
          ? newArray.push({
              id: item.id,
              name: item.name,
              category: item.categoryId,
            })
          : null,
      ),
    )
    available.map(selected =>
      search.map(
        item =>
          !!item.subFilters &&
          item.subFilters.map(subitem =>
            subitem.id === selected
              ? newArray.push({
                  id: subitem.id,
                  name: subitem.name,
                  filter: item.name,
                })
              : null,
          ),
      ),
    )
    return newArray
  },
)

export const selectManufacturersSearchFilter = createSelector(
  [getSearch],
  search => search.manufacturers,
)

export const selectActiveManufacturers = createSelector(
  [selectSearchParameters],
  params => params.manufacturers,
)

export const selectFullActiveManufacturers = createSelector(
  [selectActiveManufacturers, selectManufacturersSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectActiveRanges = createSelector(
  [selectSearchParameters],
  params => params.ranges || [],
)

export const selectFullActiveRanges = createSelector(
  [selectActiveRanges, selectVehicleRanges],
  (available, search) => {
    let newArray = []
    available.length > 0 &&
      available.map(selected =>
        search.map(item => (item.id === selected ? newArray.push(item) : null)),
      )
    return newArray
  },
)

export const selectSortBy = createSelector([selectSearchParameters], params => params.sortBy)

export const selectAvailableVehicleTags = createSelector(
  [selectAvailableFilters],
  filters => filters.vehicleTags,
)

export const selectActiveVehicleTags = createSelector(
  [selectSearchParameters],
  params => params.vehicleTags,
)

export const selectFullActiveVehicleTags = createSelector(
  [selectActiveVehicleTags, selectAvailableVehicleTags],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectAvailableVehiclePriceTags = createSelector(
  [selectAvailableFilters],
  filters => filters.vehiclePriceTags,
)

export const selectActiveDeliveryTimeVehiclePriceTags = createSelector(
  [selectSearchParameters],
  params => params.vehiclePriceTags?.filter(tag => tag.vehiclePriceTagCategoryId === 3),
)

export const selectActiveInclusiveBenefitsVehiclePriceTags = createSelector(
  [selectSearchParameters],
  params => params.vehiclePriceTags?.filter(tag => tag.vehiclePriceTagCategoryId !== 3),
)

export const selectFullActiveInclusiveBenefitsVehiclePriceTags = createSelector(
  [selectActiveInclusiveBenefitsVehiclePriceTags, selectAvailableVehiclePriceTags],
  (active, available) =>
    active.map(tag => ({
      id: tag.vehiclePriceTagCategoryId,
      name: available.find(item => item.id === tag.vehiclePriceTagCategoryId)?.name,
    })),
)

export const selectFullActiveDeliveryTime = createSelector(
  [selectActiveDeliveryTimeVehiclePriceTags, selectAvailableVehiclePriceTags],
  (active, available) => {
    if (!active) {
      return []
    }

    const activeDeliveryTags = active?.[0]?.vehiclePriceTagIds

    return available
      .filter(tag => tag.vehiclePriceTagCategoryId === 3)?.[0]
      ?.vehiclePriceTags.filter(tag => activeDeliveryTags.includes(tag.id))
  },
)

export const selectAvailableLeadTimes = createSelector([selectAvailableVehiclePriceTags], tags => {
  let leadTimes = []
  const deliveryTags = !!tags && tags.filter(tag => tag.id === 3)

  if (deliveryTags.length === 1) {
    leadTimes = deliveryTags[0].vehiclePriceTags
  }

  return [...leadTimes].sort((a, b) => a.priority - b.priority)
})

export const selectAvailableVehiclePriceTagsWithoutLeadTimes = createSelector(
  [selectAvailableVehiclePriceTags],
  tags => {
    let leadTimes = []
    const filteredTags = !!tags && tags.filter(tag => tag.id !== 3)

    if (filteredTags.length > 0) {
      leadTimes = filteredTags
    }

    return leadTimes
  },
)

export const selectSavedSearchReference = createSelector(
  [getSearch],
  search => search.savedSearchReference,
)

export const selectDoorsSearchFilter = createSelector([getSearch], search => search.doors)

export const selectActiveDoors = createSelector([selectSearchParameters], params => params.doors)

export const selectFullActiveDoors = createSelector(
  [selectActiveDoors, selectDoorsSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectSeatsSearchFilter = createSelector([getSearch], search =>
  search.seats.filter(seat => seat.id > 0),
)

export const selectActiveSeats = createSelector([selectSearchParameters], params => params.seats)

export const selectFullActiveSeats = createSelector(
  [selectActiveSeats, selectSeatsSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectFeaturesSearchFilter = createSelector([getSearch], search =>
  search.features.filter(feature => feature.id > 0),
)

export const selectActiveFeatures = createSelector(
  [selectSearchParameters],
  params => params.features,
)

export const selectFullActiveFeatures = createSelector(
  [selectActiveFeatures, selectFeaturesSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectWheelbaseSearchFilter = createSelector([getSearch], search =>
  search.wheelbase.filter(base => base.id > 0),
)

export const selectActiveWheelbase = createSelector(
  [selectSearchParameters],
  params => params.wheelbase,
)

export const selectFullActiveWheelbase = createSelector(
  [selectActiveWheelbase, selectWheelbaseSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectRoofHeightSearchFilter = createSelector([getSearch], search =>
  search.roofHeight.filter(height => height.id > 0),
)

export const selectActiveRoofHeight = createSelector(
  [selectSearchParameters],
  params => params.roofHeight,
)

export const selectFullActiveRoofHeight = createSelector(
  [selectActiveRoofHeight, selectRoofHeightSearchFilter],
  (available, search) => {
    let newArray = []
    available.map(selected =>
      search.map(item => (item.id === selected ? newArray.push(item) : null)),
    )
    return newArray
  },
)

export const selectCapacitySearchFilter = createSelector([getSearch], search => search.capacity)

export const selectActivePayloadMin = createSelector(
  [selectSearchParameters],
  params => params.payloadMin,
)

export const selectActivePayloadMax = createSelector(
  [selectSearchParameters],
  params => params.payloadMax,
)

export const selectActiveLoadLengthMin = createSelector(
  [selectSearchParameters],
  params => params.loadLengthMin,
)

export const selectActiveLoadLengthMax = createSelector(
  [selectSearchParameters],
  params => params.loadLengthMax,
)

export const selectActiveLoadHeightMin = createSelector(
  [selectSearchParameters],
  params => params.loadHeightMin,
)

export const selectActiveLoadHeightMax = createSelector(
  [selectSearchParameters],
  params => params.loadHeightMax,
)

export const selectActiveLoadWidthMin = createSelector(
  [selectSearchParameters],
  params => params.loadWidthMin,
)

export const selectActiveLoadWidthMax = createSelector(
  [selectSearchParameters],
  params => params.loadWidthMax,
)

export const selectActiveCapacity = createSelector(
  [selectSearchParameters, selectCapacitySearchFilter],
  (params, limits) => {
    let active = {}

    if (params.payloadMin) {
      if (!active.payload) {
        active.payload = {}
      }
      active.payload.min = params.payloadMin
    }

    if (params.payloadMax && limits.payload.max && params.payloadMax !== limits.payload.max) {
      if (!active.payload) {
        active.payload = {}
      }
      active.payload.max = params.payloadMax
    }

    if (params.loadLengthMin) {
      if (!active.loadLength) {
        active.loadLength = {}
      }
      active.loadLength.min = params.loadLengthMin
    }

    if (
      params.loadLengthMax &&
      limits.loadLength.max &&
      params.loadLengthMax !== limits.loadLength.max
    ) {
      if (!active.loadLength) {
        active.loadLength = {}
      }
      active.loadLength.max = params.loadLengthMax
    }

    if (params.loadHeightMin) {
      if (!active.loadHeight) {
        active.loadHeight = {}
      }
      active.loadHeight.min = params.loadHeightMin
    }

    if (
      params.loadHeightMax &&
      limits.loadHeight.max &&
      params.loadHeightMax !== limits.loadHeight.max
    ) {
      if (!active.loadHeight) {
        active.loadHeight = {}
      }
      active.loadHeight.max = params.loadHeightMax
    }

    if (params.loadWidthMin) {
      if (!active.loadWidth) {
        active.loadWidth = {}
      }
      active.loadWidth.min = params.loadWidthMin
    }

    if (
      params.loadWidthMax &&
      limits.loadWidth.max &&
      params.loadWidthMax !== limits.loadWidth.max
    ) {
      if (!active.loadWidth) {
        active.loadWidth = {}
      }
      active.loadWidth.max = params.loadWidthMax
    }

    return active
  },
)

export const selectActiveElectricRange = createSelector(
  [selectSearchParameters],
  params => params.electricRange,
)

export const selectActiveElectricHomeChargeTime = createSelector(
  [selectSearchParameters],
  params => params.electricHomeChargeTime,
)

export const selectActiveElectricFastestChargeRate = createSelector(
  [selectSearchParameters],
  params => params.electricFastestChargeRate,
)

export const selectActiveElectricBatteryCapacity = createSelector(
  [selectSearchParameters],
  params => params.electricBatteryCapacity,
)

export const selectActiveBootspaces = createSelector(
  [selectSearchParameters],
  params => params.bootSpace,
)

export const selectSearchAnalyticsEventData = createSelector(
  [
    selectSearchParameters,
    selectVehicleRanges,
    selectFullActiveVehicleTags,
    selectAvailableVehiclePriceTags,
  ],
  (params, ranges, tags, available) => {
    const textValueVehicle = getTextValueForVehicleAnalytics(params, ranges, tags)
    const vehiclePriceTag = params.vehiclePriceTags.reduce((total, tag) => {
      if (tag.vehiclePriceTagIds.length > 0) {
        return [...total, ...tag.vehiclePriceTagIds]
      }

      const fullTag = available.filter(avail => avail.id === tag.vehiclePriceTagCategoryId)

      if (fullTag.length === 1) {
        const ids = fullTag[0].vehiclePriceTags.map(vpt => vpt.id)
        return [...total, ...ids]
      }

      return [...total]
    }, [])

    return {
      vehicleType:
        params.vehicleTypes.constructor === Array ? params.vehicleTypes : [params.vehicleTypes],
      leaseType: params.leaseTypes.constructor === Array ? params.leaseTypes : [params.leaseTypes],
      term: params.terms,
      mileage: params.mileages,
      monthlyPaymentMin: params.monthlyPaymentMin,
      monthlyPaymentMax: params.monthlyPaymentMax,
      initialPaymentMin: params.initialPaymentMin,
      initialPaymentMax: params.initialPaymentMax,
      manufacturer: params.manufacturers,
      range: params.ranges,
      fuelType: params.fuelTypes,
      bodyStyle: params.bodyStyles,
      driveType: params.driveTypes,
      transmissionType: params.transmissionTypes,
      doors: params.doors,
      seats: params.seats,
      searchTerm: params.searchTerm,
      stockStatus: params.inStock ? [params.inStock] : [],
      vehiclePriceTag,
      vehicleTag: params.vehicleTags,
      textValueVehicle,
    }
  },
)

export const selectIsSearchSaved = createSelector([getSearch], search => search.isSearchSaved)

export const selectSearchSliceByParameter = (state, parameter, typeName) => {
  const search = state.search

  switch (parameter) {
    case FILTER_PERFORMANCE:
      return search[parameter].filter(i => i.typeName === typeName).map(i => i)
    case FILTER_MONTHLY_PAYMENT:
    case 'totalUpfront':
    case 'engineSize':
      return {
        min: search[`${parameter}Min`],
        max: search[`${parameter}Max`],
      }
    case FILTER_CAPACITY:
      return {
        min: search.capacity.payload.min,
        max: search.capacity.payload.max,
      }
    default:
      return search[parameter]
  }
}

export const selectSearchParametersFilterByParameter = (state, parameter) => {
  const search = state.search.searchParameters

  switch (parameter) {
    case 'monthlyPayment':
    case 'totalUpfront':
    case 'engineSize':
      return {
        min: search[`${parameter}Min`],
        max: search[`${parameter}Max`],
      }
    default:
      return search[parameter]
  }
}

export const selectAllFullSearchParameters = createSelector([selectSearchParameters], params => {
  let filledParameters = {}
  for (const param in params) {
    if (typeof params[param] === 'string' || typeof params[param] === 'number') {
      filledParameters[param] = params[param]
    } else {
      let arrayOfValues = []
      for (let value in params[param]) {
        arrayOfValues.push(filterLabelPrettyName(param, params[param][value]))
      }
      filledParameters[param] = arrayOfValues
    }
  }

  return filledParameters
})

export const selectAiInteractionDetails = createSelector(
  [getSearch],
  search => search.aiInteractionDetails || '',
)

export const selectAiConversationId = createSelector(
  [selectAiInteractionDetails],
  details => details.conversationId,
)

export const selectAiReasoningSummary = createSelector(
  [selectAiInteractionDetails],
  details => details.reasoningSummary || '',
)

export const selectAiConversationHistory = createSelector(
  [getSearch],
  search => search.aiConversationHistory || [],
)
