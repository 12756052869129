import { createAction, createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  comparison: [],
  priceAlertList: [],
  priceAlertExpiredList: [],
  priceAlertWithDealsAndVehicleList: [],
  expiredPriceAlertWithVehicleList: [],
  stalePriceAlerts: false,
}

const locoSpaceSlice = createSlice({
  name: 'locoSpace',
  initialState: INITIAL_STATE,
  reducers: {
    addPriceAlertList: (state, action) => {
      state.priceAlertList = action.payload
      state.stalePriceAlerts = true
    },
    addExpiredPriceAlertList: (state, action) => {
      state.priceAlertExpiredList = action.payload
      state.stalePriceAlerts = true
    },
    clearExpiredPriceAlertList: state => {
      state.priceAlertExpiredList = INITIAL_STATE.priceAlertExpiredList
    },
    clearPriceAlertList: state => {
      state.priceAlertList = INITIAL_STATE.priceAlertList
    },
    addPriceAlertWithDealsAndVehicleList: (state, action) => {
      const alreadyExists = state.priceAlertWithDealsAndVehicleList.filter(
        deal => deal.priceAlert.vehicleId === action.payload.priceAlert.vehicleId,
      )
      const isExpired = state.expiredPriceAlertWithVehicleList.filter(
        deal => deal.priceAlert.vehicleId === action.payload.priceAlert.vehicleId,
      )

      if (alreadyExists) {
        state.priceAlertWithDealsAndVehicleList = [
          ...state.priceAlertWithDealsAndVehicleList.filter(
            deal => deal.priceAlert.vehicleId !== action.payload.priceAlert.vehicleId,
          ),
          action.payload,
        ]
      } else {
        if (isExpired) {
          state.expiredPriceAlertWithVehicleList = [
            ...state.expiredPriceAlertWithVehicleList.filter(
              deal => deal.priceAlert.vehicleId !== action.payload.priceAlert.vehicleId,
            ),
          ]
        }
        state.priceAlertWithDealsAndVehicleList = [
          ...state.priceAlertWithDealsAndVehicleList,
          action.payload,
        ]
      }
      state.stalePriceAlerts = false
    },
    addExpiredPriceAlertWithDealsAndVehicleList: (state, action) => {
      const alreadyExists = state.expiredPriceAlertWithVehicleList.filter(
        deal => deal.priceAlert.vehicleId === action.payload.priceAlert.vehicleId,
      )
      if (alreadyExists) {
        state.expiredPriceAlertWithVehicleList = [
          ...state.expiredPriceAlertWithVehicleList.filter(
            deal => deal.priceAlert.vehicleId !== action.payload.priceAlert.vehicleId,
          ),
          action.payload,
        ]
      } else {
        state.expiredPriceAlertWithVehicleList = [
          ...state.expiredPriceAlertWithVehicleList,
          action.payload,
        ]
      }
      state.stalePriceAlerts = false
    },
    removePriceAlert: (state, action) => {
      state.priceAlertList = [
        ...state.priceAlertList.filter(deal => deal.vehicleId !== action.payload),
      ]
      state.priceAlertExpiredList = [
        ...state.priceAlertExpiredList.filter(deal => deal.vehicleId !== action.payload),
      ]
      state.priceAlertWithDealsAndVehicleList = [
        ...state.priceAlertWithDealsAndVehicleList.filter(
          deal => deal.priceAlert.vehicleId !== action.payload,
        ),
      ]
      state.expiredPriceAlertWithVehicleList = [
        ...state.expiredPriceAlertWithVehicleList.filter(
          deal => deal.priceAlert.vehicleId !== action.payload,
        ),
      ]
    },
    removeExpiredPriceAlert: (state, action) => {
      state.priceAlertExpiredList = [
        ...state.priceAlertExpiredList.filter(deal => deal.vehicleId !== action.payload),
      ]
      state.expiredPriceAlertWithVehicleList = [
        ...state.expiredPriceAlertWithVehicleList.filter(
          deal => deal.priceAlert.vehicleId !== action.payload,
        ),
      ]
    },
    clearPriceAlertWithDealsAndVehicleList: state => {
      state.priceAlertWithDealsAndVehicleList = INITIAL_STATE.priceAlertWithDealsAndVehicleList
      state.stalePriceAlerts = true
    },
    addVehicleToComparisonList: (state, action) => {
      if (!state.comparison.includes(action.payload)) {
        state.comparison = [...state.comparison, action.payload]
      } else {
        return state
      }
    },
    removeVehicleFromComparisonList: (state, action) => {
      state.comparison = [...state.comparison.filter(item => item.vehicle.id !== action.payload)]
    },
    clearComparisonList: state => {
      state.comparison = INITIAL_STATE.comparison
    },
    clearLocoSpaceState: () => {
      return INITIAL_STATE
    },
  },
})
export const getVehicleDataForComparison = createAction(
  'locoSpace/getVehicleDataForComparison',
  id => {
    return {
      payload: id,
    }
  },
)

export const {
  addPriceAlertList,
  addExpiredPriceAlertList,
  clearExpiredPriceAlertList,
  clearPriceAlertList,
  addPriceAlertWithDealsAndVehicleList,
  addExpiredPriceAlertWithDealsAndVehicleList,
  clearPriceAlertWithDealsAndVehicleList,
  removePriceAlert,
  removeExpiredPriceAlert,
  addVehicleToComparisonList,
  removeVehicleFromComparisonList,
  clearComparisonList,
  clearLocoSpaceState,
} = locoSpaceSlice.actions

export default locoSpaceSlice.reducer
